<template>
  <div class="" id="dashboard-right">
    <div class="section is-paddingless" style="background:#29434e; ">

      <nav class="navbar" style="background:#29434e;" role="navigation" aria-label="main navigation">

        <div class="navbar-brand">
          <a class="navbar-item" href="/dashboard/start">
            <img class="" src="/assets/logo_bar.png">
          </a>
        </div>

      </nav>

    </div>
    <div class="section" style="background:#819ca9; padding-bottom:0px;">
      <route-text-resource type="title" style="" />
      <route-text-resource type="subtitle" style="margin-bottom:35px" />
      <dashboard-quick-menu v-if="showQuickMenu" v-bind:menu="quickMenuName" />
      <div class="level" v-else />
    </div>

    <div class="section" style="padding-bottom:15px; padding-top:30px;">
      <route-text-resource type="description" />
    </div>

    <div class="section" style="padding-top:15px; max-width:1500px;">
      <div class="box" style="padding:30px; border-radius:10px;">
        <router-view :key="$route.fullPath" />
      </div>
    </div>

  </div>
</template>

<script>

  import RouteTextResource from '@/components/RouteTextResource'
  import DashboardQuickMenu from '@/components/DashboardQuickMenu'

  export default {
    props: ['showQuickMenu','quickMenuName'],
    data : function () {
      return {

      }
    },
    computed: {
      notifications() {
        return this.$store.getters["user/notifications"];
      }
    },
    components: {
      RouteTextResource,
      DashboardQuickMenu
    },
    mounted() {
    }
  }

</script>

<style>

  #dashboard-right {
    padding-left:96px;
  }

  #dashboard-right .section {
    padding-left:35px;
  }

  .navbar-item, .navbar-link{
    padding : 0 !important;
  }

  .navbar-item img {
    height : 52px;
    min-height : 52px;
  }
</style>
