<template lang="pug">
div
  .columns
    .column
      .file.has-name.is-fullwidth(style="margin-bottom:5px;")
        label.file-label
          input.file-input(type="file" name="resume" @change="onFileSelected")
          span.file-cta
            span.file-icon
              i.fas.fa-upload
            span.file-label Wähle ein Bild aus ...
          span.file-name
            span {{ selectedFileName }}
            span.tag.size-indicator(
              :class="[fileSizeStatus ? 'is-success' : 'is-danger']"
              ) {{ fileSizeRounded }} KB
      p.help JPEG oder PNG Bildatei mit max. ~ 10 MB !
    .column.is-one-fifth
      button.button.is-dark.is-fullwidth(
        @click="uploadSelectedFile"
        :disabled="!fileSizeStatus"
        :class="{'is-loading' : isUploading }"
        ) Upload
</template>

<script>

  export default {
    data() {
      return {
        selectedFile: null,
        selectedFileName: null,
        selectedFileSize: 0,
        isUploading: false
      }
    },
    methods: {
      onFileSelected: function(event) {
        const file = event.target.files[0];
        this.selectedFile     = file;
        this.selectedFileName = file.name;
        this.selectedFileSize = file.size;
        return;
      },
      clearInput: function() {
        this.selectedFile     = null;
        this.selectedFileName = null,
        this.selectedFileSize = 0
      },
      uploadSelectedFile: function() {
        this.isUploading = true;
        const token      = this.$store.getters['authentication/token'];
        let bodyFormData = new FormData();
        bodyFormData.append('file', this.selectedFile);
        this.$axios.post(process.env.VUE_APP_IMAGE_API + '/files',
          bodyFormData,
          {
            headers: {
              'Content-Type'  : 'multipart/form-data',
              'Authorization' : 'bearer ' + token
            }
          })
        .then((res) => {
          let uploadedFile = res.data.payload.image;
          this.clearInput();
          this.$emit('uploaded',uploadedFile);
          this.isUploading = false;
          this.$store.dispatch('alert/ALERT_SUCCESS', `"${uploadedFile.originalname} wurde erfolgreich hochgeladen !"`);
        })
        .catch((err) => {
          this.isUploading = false;
          this.$store.dispatch('alert/ALERT_ERROR', `${err.message}`);
        });
      }
    },
    computed: {
      fileSizeRounded() {
        return Math.floor(this.selectedFileSize / 1024);
      },
      fileSizeStatus() {
        let fileSize = this.fileSizeRounded;
        return fileSize <= 0 || fileSize > 1024 * 10 ? false : true;
      }
    }
  }
</script>

<style scoped>
  .size-indicator {
    position: absolute;
    right:5px;
  }
</style>
