<template>
  <div class="columns">

    <!-- /////////////////////////////////////////////////////////////////// -->

    <div class="column">

      <!-- POSTLEITZAHL -->
      <div class="field">
        <label class="label">Postleitzahl</label>
        <p class="help" style="margin-bottom:10px;">Trenne mehrere Postleitzahlen mit einem Beistrich ","</p>
        <div class="control">
          <input type="text" class="input" v-model="queryPLZ" v-on:change="update" />
        </div>
      </div>
      <!--            -->

      <!-- TIMEFRAME START -->
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Zeitraum Start</label>
            <div class="control">
              <input type="date" class="input" v-model="queryStart" v-on:change="update" />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Zeitraum Ende</label>
            <div class="control">
              <input type="date" class="input" v-model="queryEnd" v-on:change="update" />
            </div>
          </div>
        </div>
      </div>
      <!--            -->

      <div class="columns">

        <!-- TOP EVENTS -->
        <div class="column">
          <div class="field">
            <label class="label">Topevents</label>
            <div class="control">
              <label class="checkbox">
                <input type="checkbox" v-on:change="update" v-model="queryFeatured">
                Zeige nur Topevents an
              </label>
            </div>
          </div>

          <div class="field">
            <label class="label">Max. Anzahl der Events</label>
            <div class="control">
              <input type="number" class="input" v-model.number="maxEvents" v-on:change="update" />
            </div>
          </div>
        </div>
        <!--            -->

        <!-- STYLE -->
        <div class="column">
          <div class="field">
            <label class="label">Darstellung</label>
            <div class="select">
              <select v-model="queryStyle" v-on:change="update">
                <option value="box">Box</option>
                <option value="list">Liste</option>
              </select>
            </div>
          </div>

          <div class="field">
            <label class="label">Schriftgröße</label>
            <div class="control">
              <input type="number" class="input" v-model.number="fontSize" v-on:change="update" />
            </div>
          </div>
        </div>
        <!--       -->

      </div>
      <div class="columns">

        <div class="column">

          <!-- LANGUAGE -->
          <div class="field">
            <label class="label">Sprache</label>
            <div class="select">
              <select v-model="queryLanguage" v-on:change="update">
                <option value="en">Englisch</option>
                <option value="de">Deutsch</option>
              </select>
            </div>
          </div>

          <!-- CATEGORIES -->
          <div class="field">
            <label class="label">Kategorien</label>
            <p class="help" style="margin-bottom:10px;">Halte die STRG-Taste gedrückt um einzelne Einträge an- und abzuwählen.<br>Ist keine Kategorie gewählt werden alle berücksichtigt.</p>
            <div class="select is-multiple">
              <select multiple size="6" v-model="queryCategory" v-on:change="update">
                <option v-for="(id, cat) in availableEventCategories" :value="id">{{ cat }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="column">
          <form-asset-image
            v-model="topImage"
            v-on:input="update"
            label="Header - Bild" />
        </div>

      </div>

    </div>

    <!-- /////////////////////////////////////////////////////////////////// -->


  </div>
</template>

<script>

  import { availableEventCategories } from '@/_helpers/tourdata.helper'
  import FormAssetImage from '@/components/FormAssetImage'

  export default {
    props: ['value'],
    data() {
      return {
        queryPLZ : this.value.queryPLZ ? this.value.queryPLZ : '',
        queryStart : this.value.queryStart ? this.value.queryStart : '',
        queryEnd : this.value.queryEnd ? this.value.queryEnd : '',
        queryFeatured : this.value.queryFeatured ? this.value.queryFeatured : false,
        queryCategory : this.value.queryCategory ? this.value.queryCategory : [],
        queryStyle : this.value.queryStyle ? this.value.queryStyle : 'box',
        queryLanguage : this.value.queryLanguage ? this.value.queryLanguage : 'en',
        topImage : this.value.topImage ? this.value.topImage : '',
        fontSize : this.value.fontSize ? this.value.fontSize : 12,
        maxEvents : this.value.maxEvents ? this.value.maxEvents : 12,
        /////
        availableEventCategories : availableEventCategories
      }
    },
    components: {
      FormAssetImage
    },
    methods: {
      update() {
        // Clean queryPLZ
        this.queryPLZ = this.queryPLZ.replace(/ /g,'').replace(/,+$/, "");
        this.$emit('input', {
          queryPLZ : this.queryPLZ,
          queryStart : this.queryStart,
          queryEnd : this.queryEnd,
          queryFeatured : this.queryFeatured,
          queryCategory : this.queryCategory,
          queryStyle : this.queryStyle,
          queryLanguage : this.queryLanguage,
          topImage : this.topImage,
          fontSize : this.fontSize,
          maxEvents : this.maxEvents
        });
      }
    }
  }

</script>

<style>

</style>
