<template>
  <div class="field">
    <label class="label">{{ label }}</label>
    <!-- <input class="input" type="color" v-model="color" v-on:input="updateParent"> -->
    <sketch v-model="colors" @input="updateParent" />
  </div>
</template>

<script>

  import { Sketch } from 'vue-color'

  export default {
    props:['label','value'],
    data: function () {
      return {
        colors: this.value,
        suckerCanvas: null,
        suckerArea: [],
        isSucking: false
      }
    },
    components: {
      Sketch
    },
    methods: {
      updateParent() {
        const rgba = this.colors.rgba;
        this.$emit("input", rgba);
      }
    }
  }

</script>

<style>
</style>
