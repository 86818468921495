export const availableEventCategories = {
  "Ausstellung" : 5,
  "Kunstausstellung" : 75,
  "Kunstmesse" : 74,
  "Thematische Ausstellung" : 70,
  "Brauchtumsveranstaltung" : 91,
  "Adventmarkt" : 113,
  "Almabtrieb, Almfest" : 39,
  "Fasching" : 37,
  "Heimat-, Brauchtumsabend" : 35,
  "Osterveranstaltung" : 33,
  "Weihnachts-, Adventveranstaltung" : 29,
  "Sonstige Brauchtumsveranstaltung" : 95,
  "Festival" : 36,
  "Führungen, Fahrten, Touren" : 9,
  "Abenteuertour" : 26,
  "Ausflug" : 25,
  "Führung" : 8,
  "geführte Wanderung" : 24,
  "Nostalgiefahrt" : 11,
  "Pferdeschlitten-, Pferdekutschenfahrt" : 23,
  "Rundfahrt" : 118,
  "Schifffahrt" : 119,
  "Stadtführung" : 22 ,
  "Sonstige Tour" : 21,
  "Kabarett" : 34,
  "Kulinarisches" : 69,
  "Bierfest" : 107,
  "Brunch" : 68,
  "Degustation, Verkostung" : 109,
  "Diverses Kulinarisches" : 64,
  "Kochshow" : 110,
  "Kulinarisches Fest" : 114,
  "Mostkost, Mostfest" : 67,
  "regionaltypisches kulinarisches Fest" : 108,
  "Spezialitätenwoche" : 66,
  "Weinfest" : 65,
  "Kurs" : 63,
  "handwerklicher Hobbykurs" : 99,
  "Kochkurs" : 62,
  "künstlerischer Hobbykurs" : 61,
  "Sportkurs" : 76,
  "Sonstiger Kurs" : 59,
  "Markt, Messe" : 90,
  "Bauernmarkt" : 106,
  "Handwerksmarkt" : 115,
  "Kunstmarkt" : 120,
  "Messe" : 73,
  "Straßen-, Flohmarkt" : 4,
  "Tag der Offenen Tür" : 72,
  "Wirtschafts-, Sonderschau" : 71,
  "Sonstiger Markt" : 93,
  "Musikveranstaltung" : 13,
  "Ballett" : 117,
  "Frühschoppen, Platz- oder Kurkonzert" : 53,
  "Konzert" : 112,
  "Musical" : 50,
  "Oper" : 49,
  "Operette" : 48,
  "Tanz" : 57,
  "Volksmusik, volkstümliche Musik" : 12,
  "Sonstige Musikveranstaltung" : 46,
  "Religiöse Veranstaltung" : 45,
  "Berg-, Feld-, Seemesse" : 44,
  "Gottesdienst, Messe" : 43,
  "Prozession" : 42,
  "Wallfahrt" : 41,
  "Sonstige religiöse Veranstaltung" : 40,
  "Sportveranstaltung" : 2,
  "Theater" : 30,
  "Vergnügungsveranstaltung" : 3,
  "Ball, Tanzveranstaltung" : 38,
  "Fest" : 7,
  "Folklore, Umzug" : 10,
  "Show, Tanzvorführung" : 6,
  "Silvester, Neujahr" : 32,
  "Stadt-, Orts-, Vereinsfest" : 31,
  "Zirkus" : 28,
  "Sonstige Vergnügungsveranstaltung" : 27,
  "Vorträge" : 20,
  "Dia-Schau" : 121,
  "Diskussion" : 19,
  "Filmvorführung" : 18,
  "Kongress, Tagung" : 17,
  "Lesung" : 16,
  "Seminar, Workshop" : 15,
  "Vortrag" : 14
}
