<template>
  <div class="columns">

    <!-- /////////////////////////////////////////////////////////////////// -->

    <div class="column">

      <!-- FONT STYLE -->
      <div class="field">
        <label class="label">Text Style</label>
        <div class="control">
          <div class="select">
            <select v-on:change="update" v-model="fontStyle">
              <option value="modern"> Modern </option>
              <option value="slab">   Slab   </option>
              <option value="script"> Script </option>
            </select>
          </div>
        </div>
      </div>
      <!--            -->

      <!-- FONT COLOR -->
      <div class="field">
        <label class="label">Text Farbe</label>
        <div class="control">
          <input class="input" type="color" v-model="fontColor" v-on:change="update" />
        </div>
      </div>
      <!--            -->

      <!-- TEXT ALIGN -->
      <div class="field">
        <label class="label">Text Ausrichtung</label>
        <div class="control">
          <div class="select">
            <select v-on:change="update" v-model="textAlign">
              <option value="left">   Links   </option>
              <option value="center"> Mitte   </option>
              <option value="right">  Rechts  </option>
            </select>
          </div>
        </div>
      </div>
      <!--            -->

    </div>

    <!-- /////////////////////////////////////////////////////////////////// -->

    <div class="column">

      <!-- TEXT SIZE -->
      <div class="field">
        <label class="label">Text Größe</label>
        <div class="control">
          <div class="select">
            <select v-on:change="update" v-model="textSize">
              <option value="small">   Klein       </option>
              <option value="default"> Standard    </option>
              <option value="large">   Groß        </option>
              <option value="auto">    Automatisch </option>
            </select>
          </div>
        </div>
      </div>
      <!--            -->

      <!-- FONT SHADOW -->
      <div class="field">
        <label class="label">Text Schatten</label>
        <div class="control">
          <div class="select">
            <select v-on:change="update" v-model="textShadow">
              <option value="none">  Aus     </option>
              <option value="white"> Weiß    </option>
              <option value="black"> Schwarz </option>
            </select>
          </div>
        </div>
      </div>
      <!--            -->

      <!-- ???? ????? -->
      <div class="field">
        <label class="label"></label>
        <div class="control">

        </div>
      </div>
      <!--            -->

    </div>

  </div>
</template>

<script>

  export default {
    props: ['value'],
    data() {
      return {
        fontStyle   : this.value.fontStyle ? this.value.fontStyle : 'modern',
        fontColor   : this.value.fontColor ? this.value.fontColor : '#000000',
        textAlign   : this.value.textAlign ? this.value.textAlign : 'left',
        textSize    : this.value.textSize ? this.value.textSize : 'default',
        textShadow  : this.value.textShadow ? this.value.textShadow : 'none'
      }
    },
    methods: {
      update() {
        this.$emit('input', {
          fontStyle  : this.fontStyle,
          fontColor  : this.fontColor,
          textAlign  : this.textAlign,
          textSize   : this.textSize,
          textShadow : this.textShadow
        });
      }
    }
  }

</script>

<style scoped>
</style>
