import Vue from 'vue'
import { router } from './router';
import { store  } from './_store';
import App    from './App.vue'
import axios  from 'axios'
import Buefy from 'buefy'

import 'buefy/dist/buefy.css'
import './assets/styles.scss'

// - AXIOS request interceptors
axios.interceptors.request.use(
  (config) => {
    let t = localStorage.getItem('user-token');
    if (t) {
      config.headers['Authorization'] = `bearer ${t}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// - Add AXIOS as global Vue prototype
Vue.prototype.$axios = axios;

// -
Vue.config.productionTip = false

// - Buefy ! 
Vue.use(Buefy)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
