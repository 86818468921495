<template>
  <div>
    <template v-if="!isLoading">

      <div class="field has-addons has-addons-right">
        <div class="control">
          <input class="input" type="text" placeholder="SHORT ID" v-model="shortId">
        </div>
        <div class="control">
          <a class="button is-black" :class="{'is-loading' : playerClaimLoading}" @click="createPlayerClaim">
            <span class="icon"><i class="fas fa-plus"></i></span>
            <p>Player hinzufügen</p> 
          </a>
        </div>
      </div>

      <template v-if="availablePlayers.length > 0">

        <!-- ///////////////////// -->
        <!-- PLAYER SINGLE DETAILS -->
        <!-- ///////////////////// -->

        <profile-players-single
          v-for="(player,index) in availablePlayers"
          :uncollapsed="activePlayerViewIndex == index"
          :player="player"
          :live-view-time="liveViewTime"
          :key="index">

          <!-- COLLAPSE VIEW -->
          <div class="collapse-button" @click="setActivePlayerViewIndex(null)" v-if="activePlayerViewIndex == index">
            <span class=""><i class="fas fa-chevron-up"></i></span>
          </div>
          <!-- UNCOLLAPSE VIEW -->
          <div class="uncollapse-button" @click="setActivePlayerViewIndex(index)" v-else>
            <span class=""><i class="fas fa-chevron-down"></i></span>
          </div>

        </profile-players-single>

        <!-- ///////////////////// -->

      </template>

      <!-- IF USER HAS NO PLAYERS AVAILABLE -->
      <template v-else>
        <div class="section" style="display:flex; flex-direction:column; align-items:center;">
          <h1 class="title is-4">Mit deinem Account sind derzeit keine Player verknüpft</h1>
          <div class="figure">
            <img class="" src="/assets/women_grass_tree.png">
          </div>
        </div>
      </template>

    </template>
    <loading-indicator v-else />
  </div>
</template>

<script>

  import LoadingIndicator from '@/components/LoadingIndicator';
  import ProfilePlayersSingle from '@/components/ProfilePlayersSingle';

  export default {
    data() {
      return {
        activePlayerViewIndex : null,
        shortId : "",
        playerClaimLoading : false,
        liveViewTime : Date.now(),
        liveViewInterval : null
      }
    },
    computed: {
      availablePlayers() { return this.$store.state.players.available },
      isLoading() { return this.$store.state.players.isLoading }
    },
    components: {
      LoadingIndicator,
      ProfilePlayersSingle
    },
    methods: {
      setActivePlayerViewIndex(val) {
        this.activePlayerViewIndex = val;
      },
      createPlayerClaim() {
        if (this.playerClaimLoading) { return }
        this.playerClaimLoading = true;
        this.$store.dispatch('players/CREATECLAIM', this.shortId)
        .then((result) => {
          this.playerClaimLoading = false;
        });
      }
    },
    mounted() {
      this.liveViewInterval = setInterval(() => {
        this.liveViewTime = Date.now();
      },5000);
    },
    beforeDestroy() {
      clearInterval(this.liveViewInterval);
      this.liveViewInterval = null;
    }
  }
</script>

<style scoped>

  .collapse-button {
    background:#fceb2a;
    width:50px;
    height:50px;
    display:flex;
    justify-content: center;
    align-items: center;
    color:black;
    cursor:pointer;
  }

  .uncollapse-button {
    background:#00d1b2;
    width:50px;
    height:50px;
    display:flex;
    justify-content: center;
    align-items: center;
    color:white;
    cursor:pointer;
  }

  .uncollapse-button:hover {
    background: #0aa28b;
  }

  .collapse-button:hover {
    background: #fd9207;
  }


</style>
