<template>
  <div class="columns">

    <div class="column">

      <!-- FULLSCREEN -->
      <div class="field">
        <label class="label">Vollbild</label>
        <p class="help">Soll das Video als Vollbild gespielt werden, aktiviere diese Option für ein optimales Ergebnis.</p>
        <div class="control">
          <input type="checkbox" v-model="fullscreen" v-on:change="update" />
        </div>
        
      </div>

      <!-- IAMGE BORDER SIZE -->
      <div class="field">
        <label class="label">Rahmen Breite (pixel)</label>
        <div class="control">
          <input
            class="input"
            type="number"
            min="0"
            max="30"
            v-model="imageBorderSize"
            v-on:change="update" />
        </div>
      </div>
      <!--            -->

      <!-- IMAGE BORDER COLOR -->
      <div class="field">
        <label class="label">Rahmen Farbe</label>
        <div class="control">
          <input class="input" type="color" v-model="imageBorderColor" v-on:change="update" />
        </div>
      </div>
      <!--            -->

      <!-- IMAGE SPACE -->

      <!--            -->

    </div>

    <!-- /////////////////////////////////////////////////////////////////// -->

    <div class="column">
      <form-asset-video
        v-model="videoUrl"
        v-on:input="update"
        label="Video aus Mediathek wählen" />
    </div>

  </div>
</template>

<script>

  import FormAssetVideo from '@/components/FormAssetVideo'

  export default {
    props: ['value'],
    data() {
      return {
        videoUrl          : this.value.videoUrl ? this.value.videoUrl : '',
        imageBorderSize   : this.value.imageBorderSize ? this.value.imageBorderSize : 0,
        imageBorderColor  : this.value.imageBorderColor ? this.value.imageBorderColor : '500AFA',
        fullscreen        : this.value.fullscreen ? this.value.fullscreen : false
      }
    },
    components : {
      FormAssetVideo
    },
    methods: {
      update() {
        this.$emit('input', {
          videoUrl          : this.videoUrl,
          imageBorderSize   : this.imageBorderSize,
          imageBorderColor  : this.imageBorderColor,
          fullscreen        : this.fullscreen
        });
      }
    },
    mounted() {

    }
  }

</script>

<style scoped>
</style>
