import Vue from 'vue';
import Vuex from 'vuex';

import { authentication }   from './authentication.module';
import { alert }            from './alert.module';
import { assets }           from './assets.module';
import { user }             from './user.module';
import { organization }     from './organizations.module';
import { players }     from './players.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    authentication,
    alert,
    assets,
    user,
    organization,
    players
  }
});
