<template lang="pug">
.field
  label.label.has-text-light {{ label }}
  .control
    .select
      select(v-model="input" v-on:change="updateParent")
        option(value="AT") Österreich
        option(value="DE") Deutschland
        option(value="CH") Schweiz
</template>

<script>

  export default {
    props:['region'],
    data: function () {
      return {
        label: "Region",
        placeholder: "Mindestens 5 Zeichen",
        input: "AT"
      }
    },
    methods: {
      updateParent: function() {
        this.$emit('set-region',this.input);
      }
    },
    watch: {
      region: function(val) {
        this.input = val;
      }
    }
  }

</script>

<style>
</style>
