<template>
  <div>

    <div class="columns">

      <!-- IAMGE BORDER SIZE -->
      <div class="column">
        <div class="field">
          <label class="label">Rahmen Breite (pixel)</label>
          <div class="control">
            <input
              class="input"
              type="number"
              min="0"
              max="30"
              v-model="imageBorderSize"
              v-on:change="update" />
          </div>
          <p class="help" style="margin-bottom:5px;">Gibt an wie dick der Rahmen um das Bild sein soll.</p>
        </div>
      </div>
      <!--            -->

      <!-- IMAGE BORDER COLOR -->
      <div class="column">
        <div class="field">
          <label class="label">Rahmen Farbe</label>
          <div class="control">
            <input class="input" type="color" v-model="imageBorderColor" v-on:change="update" />
          </div>
          <p class="help" style="margin-bottom:5px;">Gibt an welche Farbe der Rahmen um das Bild haben soll.</p>
        </div>
      </div>
      <!--            -->

      <!-- SWITCHER SPEED -->
      <div class="column">
        <div class="field">
          <label class="label">Geschwindigkeit (Sekunden)</label>
          <div class="control">
            <input
              class="input"
              type="number"
              min="0"
              max="1000"
              v-model="imageGallerySpeed"
              v-on:change="update" />
            <!-- <div class="select is-primary">
              <select v-model="imageGallerySpeed" v-on:change="update">
                <option value="3000">3</option>
                <option value="4000">4</option>
                <option value="5000">5</option>
                <option value="6000">6</option>
                <option value="7000">7</option>
                <option value="8000">8</option>
                <option value="9000">9</option>
                <option value="10000">10</option>
              </select>
            </div> -->
          </div>
          <p class="help" style="margin-bottom:5px;">Wieviele Sekunden soll jedes Bild angezeigt werden?</p>
        </div>
      </div>
      <!--            -->

    </div>

    <div class="columns">
      
      <!-- BACKGROUND SETTING -->
      <div class="column">
        <div class="field">
          <label class="label">Style</label>
          <div class="control">
            <div class="select is-primary">
              <select v-model="imageSizeStyle" v-on:change="update">
                <option value="default">Normal</option>
                <option value="proportionally-scaled">Proportional Skaliert</option>
                <option value="squeezed-scaled">Gestreckt Skaliert</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="column">

        <img :src="`/assets/bilder_galerie_infografik_${ imageSizeStyle == 'default' ? 1 : imageSizeStyle == 'proportionally-scaled' ? 3 : 2 }.png`" />

      </div>
      <div class="column">

        <b-field>
            <b-switch type="is-black" v-model="imageOrderRandom" @input="update" >Zufällige Reihenfolge</b-switch>
        </b-field>

      </div>

    </div>

    <!-- /////////////////////////////////////////////////////////////////// -->

    <form-asset-image-gallery v-model="imageUrls" :dates="imageActiveUntil" :links="imageLinks" v-on:input="update" v-on:dates="dates" v-on:links="links"/>

  </div>
</template>

<script>

  import FormAssetImageGallery from '@/components/FormAssetImageGallery'

  export default {
    props: ['value'],
    data() {

      return {
        imageUrls        : this.value.imageUrls          ? this.value.imageUrls        : [],
        imageActiveUntil : this.value.imageActiveUntil   ? this.value.imageActiveUntil : [],
        imageBorderSize  : this.value.imageBorderSize    ? this.value.imageBorderSize  : 0,
        imageBorderColor : this.value.imageBorderColor   ? this.value.imageBorderColor : 'FFFFFF',
        imageGallerySpeed: this.value.imageGallerySpeed  ? this.value.imageGallerySpeed / 1000 : 6,
        imageSizeStyle   : this.value.imageSizeStyle     ? this.value.imageSizeStyle   : 'default',
        imageOrderRandom : this.value.imageOrderRandom   ? this.value.imageOrderRandom : false,
        imageLinks       : this.value.imageLinks         ? this.value.imageLinks       : []
      }
    },
    components : {
      FormAssetImageGallery
    },
    methods: {
      dates(d) {
        this.imageActiveUntil = d;
        this.update();
      },
      links(l) {
        this.imageLinks = l;
        this.update();
      },
      update() {

        if (this.imageGallerySpeed < 0) { this.imageGallerySpeed = 0; }
        if (this.imageGallerySpeed > 1000) { this.imageGallerySpeed = 1000; }

        this.$emit('input', {
          imageUrls         : this.imageUrls,
          imageActiveUntil  : this.imageActiveUntil,
          imageBorderSize   : this.imageBorderSize,
          imageBorderColor  : this.imageBorderColor,
          imageGallerySpeed : this.imageGallerySpeed * 1000,
          imageSizeStyle    : this.imageSizeStyle,
          imageOrderRandom  : this.imageOrderRandom,
          imageLinks        : this.imageLinks
        });
      }
    },
    mounted() {
      this.update();
    }
  }

</script>

<style scoped>
</style>
