<template>
  <div class="columns">

    <!-- //////////////////////////////////////////////////////////////////// -->

    <div class="column">

      <!-- Youtube ID -->
      <div class="field">
        <label class="label">Youtube ID</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="t56JFBFFKUI"
            v-model="videoId"
            v-on:change="update" />
        </div>
      </div>
      <!--            -->

    </div>

    <!-- //////////////////////////////////////////////////////////////////// -->

    <div class="column">

      <!-- PREVIEW -->
      <iframe
        :src="'https://www.youtube-nocookie.com/embed/'+videoId"
        frameborder="0"
        allow="accelerometer; encrypted-media; gyroscope;">
      </iframe>

    </div>

  </div>
</template>

<script>

  export default {
    props: ['value'],
    data() {
      return {
        videoId : this.value.videoId
      }
    },
    methods: {
      update() {
        this.$emit('input', {
          videoId : this.videoId
        });
      }
    }
  }

</script>

<style scoped>
</style>
