<template>
  <footer class="footer has-background-black" style="flex:1">
    <div class="columns" style="margin-left:105px; margin-right:10px;">
      <div class="column is-2">
        <strong><p>OIS MEDIA</p></strong>
        <p class="help has-text-light">Impressum</p>
        <p class="help has-text-light">AGB</p>
        <p class="help has-text-light">Datenschutz</p>
        <p class="help has-text-light">Kontakt</p>
      </div>
      <div class="column is-2">
        <strong><p>HILFE</p></strong>
        <p class="help has-text-light">Dokumentation</p>
        <p class="help has-text-light">FAQ</p>
        <p class="help has-text-light">Supportanfrage</p>
      </div>
      <div class="column">
        <strong><p>LINKS</p></strong>
        <p class="help has-text-light">Account</p>
        <p class="help has-text-light">Katalog</p>
        <p class="help has-text-light">Logout</p>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    data() {
      return {

      }
    }
  }
</script>

<style scoped>

</style>
