<template lang="pug">
div
  template(v-if="!isLoading")
    .columns
      .column
        file-uploader(v-on:uploaded="updateImageGrid")
    template(v-if="availableImages.length > 0")

      // SELECTED IMAGE INFORMATION DETAIL
      .columns(v-if="selectedImage")
        .column
          .card(style="border-radius:10px")
            header.card-header
              p.card-header-title
                button.delete(style="margin-right:10px" @click="clearImageSelector")
                | Ausgewähltes Bild
            .card-content
              .columns
                .column.is-one-quarter
                  .card.selector-image
                    .card-image
                      figure.image.is-16by9
                        img(:src="imageserver + '/f/' + selectedImage.filename" style="object-fit:contain")
                .column
                  p.title.is-6 {{ selectedImage.originalname }}
                  .field.has-addons.is-small
                    p.control
                      a.button.is-small(disabled)
                        span.icon.is-small
                          i.far.fa-copy
                        span Link kopieren
                    p.control
                      a.button.is-small(:href="imageserver + '/f/' + selectedImage.filename" target="_blank")
                        span.icon.is-small
                          i.far.fa-folder-open
                        span Öffnen
                    p.control
                      a.button.is-small.is-outlined.is-danger(@click="deleteImage(selectedImage._id)")
                        span.icon.is-small
                          i.fas.fa-times
                        span Löschen
      hr
      div.card.p-3

        .columns
          .column
            b-field(label="Ansicht")
              b-radio-button(v-model="viewMode"
                  native-value="box"
                  type="is-primary")
                  b-icon(icon="view-grid-outline")
                  span Box
              b-radio-button(v-model="viewMode"
                  native-value="list"
                  type="is-primary")
                  b-icon(icon="view-list")
                  span List
          .column
            b-field(label="Sortieren")
              b-select(v-model="sortKey")
                option(value="createdAt") Datum
                option(value="originalname") Filename
              b-select(v-model="sortDirection")
                option(value="desc") Absteigend
                option(value="asc") Aufsteigend
          
          .column

      hr

      // MEDIATHEK IMAGE GRID OVERVIEW
      template(v-if="viewMode == 'box'")

        .columns.is-multiline
          .column.is-1-fullhd.is-2-desktop.is-4-tablet.is-12-mobile(
            v-for="(image, index) in sortedAvailableImages"
            :key="index"
            @click="selectImageToSelector(index)"
            )
            .card.grid-card(:class="[ index === selectedIndex ? 'selectedImage' : '' ]")
              .card-image
                figure.image.is-1by1
                  img(:src="imageserver + '/f/' + image.filename" style="object-fit:contain")
              //.card-content
                .content
                  | {{ image.originalname }}

      // MEDIATHEK IMAGE LIST OVERVIEW
      template(v-else)

        b-table(
          :data="sortedAvailableImages"
          :narrowed="true"
          :hoverable="true"
          @click="selectImageFromRowToSelector")

          b-table-column(field="filename" label="" v-slot="props")
            figure.image.is-1by1
              img(:src="imageserver + '/f/' + props.row.filename" style="object-fit:contain")

          b-table-column(field="filename" label="Name" v-slot="props") {{ props.row.originalname }}

          b-table-column(field="createdAt" label="Hochgeladen" v-slot="props") {{ convertToHumanReadableDate(props.row.createdAt) }}

    template(v-else)
      .section(style="display:flex; flex-direction:column; align-items:center;")
        .figure
          img(class="" src="/assets/women_grass_tree.png")
            
  loading-indicator(v-else)
</template>

<script>

  import LoadingIndicator             from '@/components/LoadingIndicator';
  import FileUploader                 from '@/components/FileUploader';
  import moment from "moment";
  import _ from "lodash";

  export default {
    data() {
      return {
        viewMode: 'box',
        isLoading: false,
        selectedImage: null,
        selectedIndex: null,
        noDataNotification: 'Ziemlich leer hier ...',
        sortKey: 'createdAt',
        sortDirection: 'desc'
      }
    },
    computed: {
      availableImages() { return this.$store.getters["assets/images"]; },
      imageserver() { return process.env.VUE_APP_IMAGE_API },
      sortedAvailableImages() {
        return _.orderBy(this.availableImages, [this.sortKey], [this.sortDirection]);
      }
    },
    components: {
      LoadingIndicator,
      FileUploader
    },
    methods: {
      setViewMode(mode) {
        this.viewMode = mode;
      },
      selectImageToSelector: function(index) {
        this.selectedImage = this.sortedAvailableImages[index];
        // this.selectedImage = this.availableImages[index];
        this.selectedIndex = index;
      },
      updateImageGrid: function(image) {
        this.availableImages.push(image)
      },
      clearImageSelector: function() {
        this.selectedImage = null;
        this.selectedIndex = null;
      },
      deleteImage: function(id) {
        this.isLoading = true;
        this.clearImageSelector();
        this.$store.dispatch("assets/REMOVE_IMAGE", id)
        .then(r => {
          this.isLoading = false;
        });
      },
      selectImageFromRowToSelector(row) {
        this.selectedImage = row;
        console.log(row)
      },
      convertToHumanReadableDate(date) {
        return moment(date).fromNow();
        // return moment(date).format('DD.MM.YYYY, HH:mm:ss a');
      }
    },
    mounted: function () {

    }
  }

</script>

<style scoped>
  .grid-card {
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
    background:rgba(0,0,0,0.05);
  }

  .selector-image {
    border-radius: 5px;
    overflow:hidden;
    background:rgba(0,0,0,0.5);
    border: 1px solid black;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  .grid-card:hover {
    background:rgba(0,0,0,0.2);
  }

  .selectedImage {
    border: 1px solid black;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
</style>
