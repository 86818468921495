<template lang="pug">
.columns.is-marginless
  .column.dashboard-sidebar.is-narrow(style="position:fixed; height:100vh")
    aside.menu.dashboard-sidebar-menu
      ul.menu-list
        li
          router-link(to="/dashboard/start")
            span.icon.is-large
              i.fas.fa-home.fa-lg
        //- li
        //-   router-link(to="/dashboard/katalog")
        //-     span.icon.is-large
        //-       i.fas.fa-archive.fa-lg
        hr.has-background-black
        li
          b-tooltip(label="Channel" position="is-right" type="is-light")
            router-link(to="/dashboard/channel")
              span.icon.is-large
                i.fas.fa-tv.fa-lg
        li
          b-tooltip(label="Seiten" position="is-right" type="is-light")
            router-link(to="/dashboard/seiten")
              span.icon.is-large
                i.fas.fa-map.fa-lg
        li
          b-tooltip(label="Mobile Seiten" position="is-right" type="is-light")
            router-link(to="/dashboard/mobile-seiten")
              span.icon.is-large
                i.fas.fa-mobile-alt.fa-lg
        li
          b-tooltip(label="Module" position="is-right" type="is-light")
            router-link(to="/dashboard/module")
              span.icon.is-large
                i.fas.fa-th.fa-lg
        hr.has-background-black
        li
          b-tooltip(label="Bilder" position="is-right" type="is-light")
            router-link(to="/dashboard/bilder")
              span.icon.is-large
                i.far.fa-images.fa-lg
        li
          b-tooltip(label="Videos" position="is-right" type="is-light")
            router-link(to="/dashboard/videos")
              span.icon.is-large
                i.fas.fa-film.fa-lg
        hr.has-background-black
        li
          b-tooltip(label="Account" position="is-right" type="is-light")
            router-link(to="/dashboard/profil")
              span.icon.is-large
                i.far.fa-user-circle.fa-lg
    aside.menu.dashboard-sidebar-logout
      ul.menu-list
        li
          router-link(to="/login")
            span.icon.is-large
              i.fas.fa-power-off.fa-lg
  .column(style="min-height:100vh;display:flex; flex-direction:column;" class="is-paddingless")
    router-view
    dashboard-footer
</template>

<script>

  import AlertBottom from '@/components/AlertBottom';
  import DashboardFooter from '@/components/DashboardFooter';

  export default {
    name: 'dashboard',
    data: function () {
      return {

      }
    },
    components: {
      AlertBottom,
      DashboardFooter
    },
    mounted() {
      // TELL VUEX STORE TO FETCH USER DATA
      this.$store.dispatch('user/USER_FETCH');
      this.$store.dispatch('assets/ASSETS_FETCH');
      this.$store.dispatch('assets/IMAGES_FETCH');
      this.$store.dispatch('players/FETCH');
      this.$store.dispatch('organization/FETCH');
      this.$store.dispatch('organization/FETCHPUBLIC');

    }
  }
</script>

<style scoped>

  .dashboard-sidebar {
    background:#111;
  }

  .dashboard-sidebar-logout {
    position:absolute;
    bottom:10px
  }

  .dashboard-sidebar-logout li a {
    background: rgba(0,0,0,0.2);
    border-radius:10px;
    color:white;
    transition: all 0.2s;
  }

  .dashboard-sidebar-logout li a:hover {
    background: #FF0266;
    color:white;
  }


  .dashboard-sidebar-menu li a:hover {
    color: #FF0266;
    background: rgba(0,0,0,0.1);
  }

  .dashboard-sidebar-menu li a {
    color: white
  }

  .dashboard-sidebar-menu li a.router-link-active {
    color:#FF0266;
  }

  .dashboard-sidebar-menu li a.router-link-active:hover {
    color:#FF0266;
  }


</style>
