<template lang="pug">
div
  editor-menu-bar(:editor="editor")
    .menubar.buttons(slot-scope="{commands, isActive}")
      a.button.menubar__button(
        :class="{'is-active' : isActive.heading({ level : 1 }) }"
        @click="commands.heading({ level : 1 })"
        )
        span.icon
          i.fas.fa-heading
          strong 1
      a.button.menubar__button(
        :class="{'is-active' : isActive.heading({ level : 2 }) }"
        @click="commands.heading({ level : 2 })"
        )
        span.icon
          i.fas.fa-heading
          strong 2
      a.button.menubar__button(
        :class="{'is-active' : isActive.heading({ level : 3 }) }"
        @click="commands.heading({ level : 3 })"
        )
        span.icon
          i.fas.fa-heading
          strong 3
      a.button.menubar__button(
        :class="{'is-active' : isActive.paragraph() }"
        @click="commands.paragraph"
        )
        span.icon
          i.fas.fa-paragraph
      a.button.menubar__button(
        :class="{'is-active' : isActive.bold() }"
        @click="commands.bold"
        )
        span.icon
          i.fas.fa-bold
      a.button.menubar__button(
        :class="{'is-active' : isActive.italic() }"
        @click="commands.italic"
        )
        span.icon
          i.fas.fa-italic
  .text-editor
    editor-content.content(:editor="editor")
</template>

<script>

  import {Editor, EditorContent, EditorMenuBar } from 'tiptap'
  import {Heading, Bold, Italic} from 'tiptap-extensions'

  export default {
    props:['value'],
    components: {
      EditorContent,
      EditorMenuBar
    },
    data() {
      return {
        editor: null,
      }
    },
    methods: {
      onUpdate(obj) {
        this.$emit('input', obj.getHTML())
      }
    },
    // watch: {
    //   content(val) {
    //     if (val === this.editor.getHTML()) {
    //       return;
    //     }
    //     this.editor.setContent(val);
    //   }
    // },
    mounted() {
      this.editor = new Editor({
        content: this.value,
        extensions: [
          new Heading({ levels: [1,2,3] }),
          new Bold(),
          new Italic()
        ],
        onUpdate: this.onUpdate
      })
    },
    beforeDestroy() {
      this.editor.destroy();
    }
  }

</script>

<style scoped>

  .text-editor {
    background:#F1F1F1;
    padding:15px;
    border-radius: 5px;
    height:250px;
    overflow:scroll;
    line-height:1.2em
  }

</style>
