<template>
  <div>
    <div class="tabs">
      <ul>
        <router-link
          v-for="(item,index) in items[menu]"
          :to="item.value" tag="li" :key="item.value">
          <a>
            {{ item.name }}
          </a>
        </router-link>
        <li v-if="isEditor" class="router-link-exact-active">
          <a>
            <span class="icon"><i class="far fa-file-alt"></i></span>
            <span>Editor</span>
          </a>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>

  import _ from 'lodash';

  export default {
    props: ['menu'],
    methods: {
      onChange: function() {
        this.$router.push(this.selected);
        this.selected = "default";
      }
    },
    computed: {
      isEditor() {
        return _.includes([
          "dashboard_channel_edit",
          "dashboard_seiten_edit",
          "dashboard_module_edit"
        ],
        this.$route.name);
      }
    },
    data: function () {
      return {
        selected: "default",
        items: {
          channel: [
            {
              name: "Übersicht",
              value: "/dashboard/channel/liste"
            },
            {
              name: "Öffentliche Channel",
              value: "/dashboard/katalog?filter=channel"
            }
          ],
          seiten: [
            {
              name: "Übersicht",
              value: "/dashboard/seiten/liste"
            },
            {
              name: "Öffentliche Seiten",
              value: "/dashboard/katalog?filter=seiten"
            }
          ],
          module: [
            {
              name: "Übersicht",
              value: "/dashboard/module/liste"
            },
            {
              name: "Öffentliche Module",
              value: "/dashboard/katalog?filter=module"
            }
          ],
          profile: [
            {
              name: "Details",
              value: "/dashboard/profil/details"
            },
            {
              name: "Player",
              value: "/dashboard/profil/player"
            },
            {
              name: "Tourismus Region",
              value: "/dashboard/profil/organization"
            }
          ]
        }
      }
    },
    mounted() {

    }
  }
</script>

<style scoped>

  .router-link-exact-active {
    background: #DBDBDB;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-color: #F5F5F5;
  }

  .router-link-exact-active a {
    border-bottom-color: #DBDBDB;
    color:black;
    font-weight: 700;
  }

  a {
    font-weight:600;
  }

</style>
