<template>
  <div id="alert-display" v-if="alertType">
    <div class="notification" :class="alertClass[alertType]">
      <strong>{{ alertMessage }}</strong>
    </div>
  </div>
<!-- #alert-display(v-if="alertType")
  .notification(
    :class="alertClass[alertType]"
    )
    button.delete(@click="clearAlert")
    h2.subtitle.has-text-black
      strong {{ alertMessage }}
    .progressbar(:style="{width:alertProgress + '%'}") -->
</template>

<script>
  export default {
    data() {
      return {
        alertClass: {
          error: 'is-danger',
          success: 'is-success'
        }
      }
    },
    computed: {
      alertType() {
        return this.$store.getters['alert/type']
      },
      alertMessage() {
        return this.$store.getters['alert/message']
      },
      alertProgress() {
        return this.$store.getters['alert/progress']
      }
    },
    methods: {
      clearAlert() {
        this.$store.dispatch('alert/ALERT_CLEAR')
      }
    }
  }
</script>

<style>
  #alert-display {
    position: fixed;
    top: 60px;
    right: 20px;
  }

  #alert-display .notification {
    margin:0;
    border-radius: 5px;
    padding-bottom:20px;
    padding-right:20px;
    padding-left:20px;
  }

</style>
