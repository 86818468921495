<template>
  <div class="field is-horizontal">
    <label class="field-label label"> {{ label }} </label>
    <div class="field-body control">
      <input
        class="input"
        type="text"
        :placeholder="placeholder"
        ref="textInput"
        :value="value"
        @input="updateParent()" />
    </div>
  </div>
</template>

<script>

  export default {
    props: ['value'],
    data: function () {
      return {
        label: "Name",
        placeholder: "Mindestens 5 Zeichen",
        input: ""
      }
    },
    methods: {
      updateParent: function() {
        this.$emit('input',this.$refs.textInput.value);
      }
    },
  }

</script>

<style>
</style>
