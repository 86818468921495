<template>
  <div class="columns">

    <div class="column">

      <!-- IAMGE BORDER SIZE -->
      <div class="field">
        <label class="label">Rahmen Breite (pixel)</label>
        <div class="control">
          <input
            class="input"
            type="number"
            min="0"
            max="30"
            v-model="imageBorderSize"
            v-on:change="update" />
        </div>
      </div>
      <!--            -->

      <!-- IMAGE BORDER COLOR -->
      <div class="field">
        <label class="label">Rahmen Farbe</label>
        <div class="control">
          <input class="input" type="color" v-model="imageBorderColor" v-on:change="update" />
        </div>
      </div>
      <!--            -->

      <!-- IMAGE SPACE -->

      <!--            -->

    </div>

    <!-- /////////////////////////////////////////////////////////////////// -->

    <div class="column">
      <form-asset-image
        v-model="imageUrl"
        v-on:input="update"
        label="Bild aus Mediathek wählen" />
    </div>

  </div>
</template>

<script>

  import FormAssetImage from '@/components/FormAssetImage'

  export default {
    props: ['value'],
    data() {
      return {
        imageUrl          : this.value.imageUrl ? this.value.imageUrl : '',
        imageBorderSize   : this.value.imageBorderSize ? this.value.imageBorderSize : 0,
        imageBorderColor  : this.value.imageBorderColor ? this.value.imageBorderColor : '500AFA'
      }
    },
    components : {
      FormAssetImage
    },
    methods: {
      update() {
        this.$emit('input', {
          imageUrl          : this.imageUrl,
          imageBorderSize   : this.imageBorderSize,
          imageBorderColor  : this.imageBorderColor
        });
      }
    }
  }

</script>

<style scoped>
</style>
