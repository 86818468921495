<template lang="pug">
div
  template(v-if="type == 'title'")
    h1.title {{ getText(type) }}
  template(v-if="type == 'subtitle'")
    h2.subtitle {{ getText(type) }}
  template(v-if="type == 'description'")
    p(style="color:#555" v-html="getText(type)")
</template>

<script>

  export default {
    data : function () {
      return {
        "dashboard_start" : {
          title: "Dashboard",
          subtitle: "Web-based Digital Synage",
          description: "Herzlich willkommen im OIS Media Dashboard !"
        },
        "dashboard_start_changelog" : {
          title : "Dashboard",
          subtitle : "",
          description: ""
        },
        "dashboard_katalog_liste" : {
          title: "Katalog",
          subtitle: "",
          description: "Hier findest du eine Übersicht von gelisteten Channel, Seiten oder Module anderer User.</br> Du kannst als öffentlich gelistete Elemente in deinen eigenen Elementen einbauen."
        },
      //// CHANNEL OBJECT ////
        "dashboard_channel_liste" : {
          title: "Channel",
          subtitle: "Übersicht deiner Channel",
          description: "Das sind alle deine bisher erstellten Channel.</br>Fahre mit der Maus über einen Eintrag um mehr Optionen zu erhalten."
        },
        "dashboard_channel_erstellen" : {
          title: "Channel",
          subtitle: "Erstelle einen neuen Channel",
          description: ""
        },
        "dashboard_channel_edit" : {
          title: "Channel",
          subtitle: "Bearbeite deinen Channel",
          description: ""
        },
      //// SEITEN OBJECT ////
        "dashboard_seiten_liste" : {
          title: "Seiten",
          subtitle: "Übersicht deiner Seiten",
          description: "Das sind alle deine bisher erstellten Seiten.</br>Fahre mit der Maus über einen Eintrag um mehr Optionen zu erhalten."
        },
        "dashboard_seiten_erstellen" : {
          title: "Seiten",
          subtitle: "Erstelle eine neue Seite",
          description: ""
        },
        "dashboard_seiten_edit" : {
          title: "Seite",
          subtitle: "Bearbeite deine Seite",
          description: ""
        },
      //// MOBILE SEITEN OBJECT ////
        "dashboard_mobile_seiten_liste" : {
          title: "Mobile Seiten",
          subtitle: "Übersicht deiner Mobile Seiten",
          description: "Das sind alle deine bisher erstellten Mobilen Seiten.</br>Fahre mit der Maus über einen Eintrag um mehr Optionen zu erhalten."
        },
        "dashboard_mobile_seiten_erstellen" : {
          title: "Mobile Seiten",
          subtitle: "Erstelle eine neue Mobile Seite",
          description: ""
        },
        "dashboard_mobile_seiten_edit" : {
          title: "Mobile Seite",
          subtitle: "Bearbeite deine Mobile Seite",
          description: ""
        },
      //// MODULE OBJECT ////
        "dashboard_module_liste" : {
          title: "Module",
          subtitle: "Übersicht deiner Module",
          description: "Das sind alle deine bisher erstellten Module.</br>Fahre mit der Maus über einen Eintrag um mehr Optionen zu erhalten."
        },
        "dashboard_module_erstellen" : {
          title: "Module",
          subtitle: "Erstelle ein neues Modul",
          description: ""
        },
        "dashboard_module_edit" : {
          title: "Module",
          subtitle: "Bearbeite dein Modul",
          description: ""
        },
      //// BILDER OBJECT ////
        "dashboard_bilder_mediathek" : {
          title: "Bilder",
          subtitle: "Lade deine eigenen Bilder hoch",
          description: "Das sind alle deine bisher hochgeladenen Bilder.</br>Klicke auf eines der Bilder um mehr Optionen zu erhalten."
        },
        "dashboard_videos_mediathek" : {
          title: "Videos",
          subtitle: "Lade deine eigenen Videos hoch",
          description: "Das sind alle deine bisher hochgeladenen Videos.</br>Klicke auf ein Video um mehr Optionen zu erhalten."
        },
      //// PROFIL OBJECT /////
        "dashboard_profil_details" : {
          title: "Account",
          subtitle: "Benutzerübersicht & Verwaltung",
          description: ""
        },
        "dashboard_profil_player" : {
          title: "Account",
          subtitle: "Player Übersicht",
          description: "Das sind alle deine bisher verbundenen OIS Player.</br>Fahre mit der Maus über einen Eintrag um mehr Optionen zu erhalten."
        },
        "dashboard_profil_organization" : {
          title: "Account",
          subtitle: "",
          description: "Erstelle und verwalte deine eigene OIS Tourismus Region.</br>Andere User können dieser Region beitreten."
        },
        "fallback" : {
          title: "ERROR LOADING TITLE",
          subtitle: "ERROR LOADING SUBTITLE",
          description: "ERROR LOADING DESCRIPTION"
        }
      }
    },
    methods: {
      getText(type) {
        return this[this.$route.name] ? this[this.$route.name][type] : this.fallback[type];
      }
    },
    props: ['type']
  }

</script>

<style scoped>
  h1.title {
    font-weight:700;
  }
</style>
