<template>
  <div>

    <h1 class="title is-4">Video hochladen</h1>

    <!-- VIDEO UPLOAD UI -->
    <div v-if="!isUploading">
      <div class="file has-name is-fullwidth">
        <label class="file-label">
          <input class="file-input" type="file" name="resume" @change="onChosenFile">
          <span class="file-cta">
            <span class="file-icon">
              <i class="fas fa-upload"></i>
            </span>
            <span class="file-label">
              Datei auswählen
            </span>
          </span>
          <span class="file-name">
            {{ chosenFile ? chosenFile.name : '' }}
          </span>
        </label>
      </div>
      <br>
      <button class="button is-fullwidth is-primary" v-if="chosenFile" @click="uploadVideo">Starte Upload</button>
    </div>
    <div v-else>
      <progress ref="uploadProgress" class="progress is-primary" :value="uploadProgress" max="100"></progress> 
    </div>
    
    <br>
    <hr>
    <br>

    <h1 class="title is-4">Verfügbare Videos</h1>

    <!-- LIST AVAILABLE VIDEO FILES -->
    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th><abbr title="Index">#</abbr></th>
          <th><abbr title="Original Name">Or. N.</abbr></th>
          <th><abbr title="Filegröße">Gr.</abbr></th>
          <th><abbr title="Status">Status</abbr></th>
          <th><abbr title="Optionen">Optionen</abbr></th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <th><abbr title="Index">#</abbr></th>
          <th><abbr title="Original Name">Or. N.</abbr></th>
          <th><abbr title="Filegröße">Gr.</abbr></th>
          <th><abbr title="Status">Status</abbr></th>
          <th><abbr title="Optionen">Optionen</abbr></th>
        </tr>
      </tfoot>
      <tbody>
        <tr v-for="(video, index) in videos" :key="video._id">

          <!-- INDEX ORDER NUMBER -->
          <th>{{ index }}</th>

          <!-- FILE NAME ORIGINAL -->
          <td>
            {{ video.originalname.substring(0,30) }}
            {{ video.originalname.length > 30 ? ' ...' : '' }}
          </td>

          <!-- FILE SIZE -->
          <td>{{ video.filesize }} MB</td>

          <!-- VIDEO STATUS -->
          <td v-if="video.status =='ready'">
            <span class="tag is-success">Bereit</span>
          </td>
          <td v-if="video.status =='processing'">
            <div class="buttons">
              <button class="button is-loading is-small"></button>
              <button class="button is-static is-small">Transcoding Process</button>
              
            </div>
            <progress :id="'progress_'+video._id" class="progress is-small" value="0" max="100"></progress>
          </td>
          <td v-if="video.status =='inqueue'">
            <span class="tag is-warning">In Warteschlange</span>
          </td>
          <td v-if="video.status =='failed'">
            <span class="tag is-danger">Fehlgeschlagen</span>
          </td>

          <!-- OPTIONEN BUTTONS-->
          <td>
            <div class="buttons" v-if="video.status == 'ready'">
              <button class="button is-small is-danger is-outlined" @click="deleteFile(video._id)">Löschen</button>
              <a class="button is-small is-link is-outlined" :href="'https://videos.containerapp1.dev/f/' + video.filename" target="_blank">Ansehen</a>
              <button class="button is-small is-primary is-outlined">Download</button>
            </div>
          </td>

        </tr>
      </tbody>
    </table>
    
  </div>
</template>

<script>

  import LoadingIndicator             from '@/components/LoadingIndicator';
  import io from 'socket.io-client'

  export default {
    data() {
      return {
        isLoading: false,
        uploadProgress: 0,
        chosenFile : null,
        isUploading : false,
        videos : [],
        socket : null
      }
    },
    computed: {
      
    },
    components: {
      LoadingIndicator
    },
    methods: {
      onChosenFile(f) {
        const file = event.target.files[0];
        this.chosenFile = file;
      },
      clearFile() {
        this.chosenFile = null;
      },
      clearUpload() {
        this.uploadProgress = 0;
        this.isUploading = false;
      },
      uploadVideo() {
        this.isUploading = true;
        let bodyFormData = new FormData();
        bodyFormData.append('file', this.chosenFile);
        this.$axios.post(process.env.VUE_APP_FILE_API + '/files', bodyFormData, {
          headers: {
            'Content-Type'  : 'multipart/form-data'
          },
          onUploadProgress: ( progressEvent ) => {
            this.uploadProgress = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
          }
        })
        .then((res) => {
          if (res.data.payload && res.data.payload.video) {
            this.videos.push(res.data.payload.video);
            this.joinRooms([res.data.payload.video._id]);
          }
          this.clearUpload();
          this.clearFile();
        })
        .catch((err) => {
          console.log(err.message)
          this.clearUpload();
          this.clearFile();
        });  
      },
      loadVideoFiles() {

        this.$axios.get(process.env.VUE_APP_FILE_API + '/files')
        .then(r => {
          this.videos = r.data.payload.videos;
          let idArray = this.videos.map(v => v._id);
          this.joinRooms(idArray);
        })
        .catch(err => {
          console.log(err.message);
        });

      },
      deleteFile(id) {
        this.$axios.delete(process.env.VUE_APP_FILE_API + '/files/' + id)
        .then(r => {
          this.loadVideoFiles();
        })
        .catch(err => {
          console.log(err.message)
        });

      },
      joinRooms(idArray) {
        this.socket.emit('joinrooms',idArray);
      },
      handleStatusUpdate(status) {
        const id = status.file;
        for (let i = 0; i < this.videos.length; i++) {
          if (this.videos[i]._id == id) {
            this.videos[i].status = status.status;
          }
        }
      },
      handleProgressUpdate(progress) {
        const id = progress.file;
        const bar = document.getElementById(`progress_${id}`);
        const value = parseInt(Math.round(progress.progress.percent), 10);
        if (bar) {
          bar.value = value;
        }
        for (let i = 0; i < this.videos.length; i++) {
          if (this.videos[i]._id == id) {
            this.videos[i].status = 'processing';
          }
        }
      },
      disconnectSocket() {
        // DISCONNECT SOCKET
        this.socket.close();
      }
    },
    mounted() {

      // CONNECT TO FILE SOCKET SERVER
      const socket = io(process.env.VUE_APP_FILE_API);
      socket.on('status', this.handleStatusUpdate);
      socket.on('progress', this.handleProgressUpdate);

      this.socket = socket;

      // THEN LOAD AVAILABLE VIDEO FILES
      this.loadVideoFiles()

    },
    beforeDestroy() {
      this.disconnectSocket();
    }
  }

</script>

<style scoped>

</style>
