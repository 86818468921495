<template>
  <div>

    <!-- BUTTON -->
    <a class="button is-dark is-outlined"
      @click="openModal"
      style="margin-bottom:20px"
      :disabled="chosenPages.length > 9">
      <span class="icon"><i class="fas fa-plus"></i></span>
      <strong>Seiten hinzufügen ({{ chosenPages.length }}/10)</strong>
    </a>


    <draggable v-model="chosenPages" :options="{handle:'.draghandle'}" @change="updateOrder">
      <div
        class="box"
        v-for="(page, index) in chosenPages">
        <div class="columns is-vcentered has-background-light">

          <!-- DRAG HANDLE -->
          <div class="column is-narrow">
            <a class="button draghandle is-primary">
              <span class="icon"><i class="fas fa-grip-lines"></i></span>
            </a>
          </div>

          <div class="column is-narrow">
            <a class="button is-link" :href="`/dashboard/seiten/edit/${page._id}`" target="_blank">
              <span class="icon"><i class="fas fa-edit"></i></span>
            </a>
          </div>

          <!-- PAGE POSITION IN CHANNEL -->
          <div class="column is-narrow">
            <strong>{{ index + 1 }}</strong>
          </div>

          <!-- NAME & DESCRIPTION -->
          <div class="column">
            <p><strong>{{ page.meta.name }}</strong></p>
            <p>{{ page.meta.description }}</p>
          </div>

          <!-- PAGE DURATION -->
          <div class="column is-narrow" >
            <div class="field">
              <label class="help label">Anzeige (Sek.)</label>
              <div class="control" >
                <input class="input" type="number" v-model.number="pageDurations[index]" style="max-width:170px">
              </div>
            </div>
          </div>

          <!-- PAGE REMOVE -->
          <div class="column is-narrow">
            <a class="button is-danger" @click="remove(index)">
              <span class="icon is-small"><i class="fas fa-times"></i></span>
            </a>
          </div>
        </div>
      </div>
    </draggable>

    <!-- PAGE MODAL -->
    <div class="modal" :class="{'is-active':modalIsActive}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Seite wählen</p>
          <button class="delete" @click="closeModal"></button>
        </header>
        <section class="modal-card-body">

          <!-- PAGES THE USER OWN -->
          <p class="title is-5">Deine Seiten</p>
          <p class="subtitle is-7">Seiten, die du selbst erstellt hast.</p>
          <table class="table is-fullwidth is-narrow is-hoverable is-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(page, index) in pages" @click="update(page)">
                <th>{{ index + 1 }}</th>
                <td>{{ page.meta.name }}</td>
              </tr>
            </tbody>
          </table>

          <hr>
          <!-- PAGES THE USER IS MEMBER -->
          <p class="title is-5">Mitglieds-Seiten</p>
          <p class="subtitle is-7">Seiten, welchen du als Mitglied hinzugefügt wurdest.</p>
          <table class="table is-fullwidth is-narrow is-hoverable is-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(page, index) in pagesMember" @click="update(page)">
                <th>{{ index + 1 }}</th>
                <td>{{ page.meta.name }}</td>
              </tr>
            </tbody>
          </table>

        </section>
        <footer><div class="modal-card-foot"></div></footer>
      </div>
    </div>

    <!-- TIMER DISPLAY -->
    <p class="subtitle is-5 mt-5"><strong>Zeitanzeige</strong></p>
    <hr>
    <section>

      <b-field>
        <b-switch v-model="timeDisplay.active">Aktiv</b-switch>
      </b-field>

      <div class="columns">
        <div class="column">
          <b-field label="Position">
            <b-select placeholder="Position" v-model="timeDisplay.position">
              <option value="top">Oben</option>
              <option value="bottom">Unten</option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Vordergrund Farbe">
            <input type="color" v-model="timeDisplay.foregroundColor" />
          </b-field>
        </div>
        <div class="column">
          <b-field label="Hintergrund Farbe">
            <input type="color" v-model="timeDisplay.backgroundColor" />
          </b-field>
        </div>
      </div>

    </section>

  </div>

</template>

<script>

  import draggable from 'vuedraggable'
  import { Color } from "buefy"

  export default {
    props: ['value', 'pages', 'pagesMember', 'pageDurations', 'timeDisplay'],
    data() {
      return {
        chosenPages: this.value,
        modalIsActive: false
      }
    },
    components: {
      draggable
    },
    methods: {
      move(arr, oldIndex, newIndex) {
        if (newIndex >= arr.length) {
          let k = newIndex - arr.length + 1;
          while (k--) { arr.push(undefined); }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr;
      },
      updateOrder(e) {
        let durations = this.move([...this.pageDurations], e.moved.oldIndex, e.moved.newIndex);
        this.$emit('durations', durations)

        let array = [...this.chosenPages];
        this.$emit('input', array);
      },
      update(page) {
        this.closeModal();
        let array = [...this.chosenPages];
        array.push(page);
        this.chosenPages = array;
        this.$emit('input', array);
      },
      remove(index) {
        let array = [...this.chosenPages];
        array.splice(index,1);
        this.chosenPages = array;
        this.$emit('input', array);

        let durations = [...this.pageDurations];
        durations.splice(index,1);
        this.$emit('durations', durations);
      },
      openModal() {
        this.modalIsActive = true;
      },
      closeModal() {
        this.modalIsActive = false;
      }
    }
    // watch : {
    //   value(newvalue) {
    //     this.chosenPages = newvalue;
    //   }
    // }
  }
</script>

<style scoped>

  tr { cursor:  pointer }

</style>
