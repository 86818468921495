<template>
  <div class="field is-horizontal">
    <label class="field-label label">{{ label }}</label>
    <div class="field-body control">
      <textarea
        class="textarea"
        v-model="input"
        @input="updateParent"
        :placeholder="placeholder"
        ></textarea>
    </div>
  </div>
</template>

<script>

  export default {
    props: ['value'],
    data: function () {
      return {
        label: "Beschreibung",
        placeholder: "Füge eine Beschreibung hinzu",
        input: this.value
      }
    },
    methods: {
      updateParent: function() {
        this.$emit('input', this.input)
      }
    }
  }

</script>

<style>
</style>
