<template lang="pug">
.field.is-horizontal
  label.label.field-label {{ label }}
  .control.field-body
    .select
      select(v-on:change="updateParent" :value="value" ref="modulePicker")
        option(value="standard" disabled) Modul wählen
        option(value="module-video") Video
        option(value="module-youtube") Youtube Video
        option(value="module-vimeo") Vimeo Video
        option(value="module-image") Bild
        option(value="module-image-gallery") Bildergalerie
        option(value="module-text") Text
        option(value="module-tourdata-multi") TourData Events
        option(value="module-date-time") Datum & Uhr
        option(value="module-iframe") Iframe Einbettung
        option(value="module-weather") Wetter ( Open Weather API )
        option(value="module-news-ticker") News Ticker
        option(value="module-panomax-webcam") Panomax Live Webcam
</template>

<script>

  export default {
    props:['value'],
    data() {
      return {
        label: "Vorlage wählen",
      }
    },
    methods: {
      updateParent() {
        this.$emit('input', this.$refs.modulePicker.value);
      }
    }
  }
</script>

<style scoped>
</style>
