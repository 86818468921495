<template lang="pug">
.field
  label.label.has-text-light {{ label }}
  .control
    .select
      select(v-model="input" v-on:change="updateParent")
        option(value="active") Nicht gelistet
        option(value="public") Öffentlich
</template>

<script>

  export default {
    props: ['modus'],
    data: function () {
      return {
        label: "Veröffentlichen als",
        input: "active"
      }
    },
    methods: {
      updateParent: function () {
        this.$emit("set-modus", this.input)
      }
    },
    watch: {
      modus: function(val) {
        this.input = val;
      }
    }
  }

</script>

<style>
</style>
