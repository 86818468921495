
import axios from 'axios';

const FETCH           = 'FETCH';
const FETCHPUBLIC     = 'FETCHPUBLIC';
const SAVE            = 'SAVE';
const STORE           = 'STORE';
const STOREPUBLIC           = 'STOREPUBLIC';
const IS_LOADING      = 'IS_LOADING';
const IS_MODIFIED     = 'IS_MODIFIED';

export const organization = {
  namespaced: true,
  state: {
    available: [],
    availablePublic : [],
    ////////////////////////
    isLoading : false,
    isModified  : false,
  },
  actions: {
    [FETCH] : ({ commit, dispatch, rootGetters }) => {

      commit(IS_LOADING, true);
      axios.get(process.env.VUE_APP_JUPITER_API + '/user/organizations')
      .then( r => {
        commit(IS_MODIFIED, false);
        commit(STORE, r.data && r.data.payload && r.data.payload.organizations ? r.data.payload.organizations : []);
      })
      .catch(err => {
        dispatch('alert/ALERT_ERROR', err.message, {root:true});
      })
      .finally(() => {
        commit(IS_LOADING, false);
      });

    },
    [FETCHPUBLIC] : ({ commit, dispatch, rootGetters }) => {

      commit(IS_LOADING, true);
      axios.get(process.env.VUE_APP_JUPITER_API + '/organizations')
      .then( r => {
        commit(STOREPUBLIC, r.data && r.data.payload && r.data.payload.organizations ? r.data.payload.organizations : []);
      })
      .catch(err => {
        dispatch('alert/ALERT_ERROR', err.message, {root:true});
      })
      .finally(() => {
        commit(IS_LOADING, false);
      });

    },
    [SAVE] : ({ commit, dispatch, rootGetters}, {id, body}) => {
      return new Promise((resolve, reject) => {
        commit(IS_LOADING, true);
        axios.put(process.env.VUE_APP_JUPITER_API + '/user/organizations/' + id, body)
        .then( r => {
          dispatch('alert/ALERT_SUCCESS', "Gespeichert!", {root:true});
        })
        .catch( err => {
          dispatch('alert/ALERT_ERROR', err.message, {root:true});
        })
        .finally(() => {
          commit(IS_LOADING, false);
          return resolve();
        });
      });
    }

  },
  mutations: {
    [STORE] : (state, array) => {
      state.available = array;
    },
    [STOREPUBLIC] : (state, array) => {
      state.availablePublic = array.filter(o => o.meta.listed);
    },
    [IS_LOADING] : (state, bool) => {
      state.isLoading = bool;
    },
    [IS_MODIFIED] : (state, bool) => {
      state.isModified = bool;
    }
  },
  getters: {
    public(state, getters, rootState, rootGetters) {
      return state.availablePublic
    },
  }
}
