import { render, staticRenderFns } from "./DashboardQuickMenu.vue?vue&type=template&id=6411ad46&scoped=true&"
import script from "./DashboardQuickMenu.vue?vue&type=script&lang=js&"
export * from "./DashboardQuickMenu.vue?vue&type=script&lang=js&"
import style0 from "./DashboardQuickMenu.vue?vue&type=style&index=0&id=6411ad46&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6411ad46",
  null
  
)

export default component.exports