<template lang="pug">
div
  .field
    label.label {{ label }}

    // BUTTON TO OPEN MODAL AND SET URL
    a.button.is-light(
      v-if="!value"
      @click="openModal"
      )
      span.icon
        i.fas.fa-plus
      span Aus deiner Mediathek auswählen

    // BOX TO SHOW IMAGE IF URL IS SET
    template(v-else)
      .box
        video(@click="openModal" style="object-fit:contain" controls)
          source(:src="value")
      a.button.is-light(
        @click="setImage('')"
        )
        span.icon
          i.fas.fa-minus-circle
        span Video entfernen

  // MODAL TO CHOOSE IMAGE
  .modal(:class="[modalActive ? 'is-active' : '']")
    .modal-background
    .modal-card
      header.modal-card-head
        p.modal-card-title Videos
        a.button.delete.is-large.is-primary(@click="closeModal")
      section.modal-card-body

        // MEDIATHEK IMAGE GRID OVERVIEW
        .box.video-box( v-for="(video, index) in readyVideos" :key="index" @click="setImage('https://videos.containerapp1.dev/f/' + video.filename)")
         | {{ video.originalname }}
</template>

<script>

  //import {getAssets}   from '@/_services/assets.service';

  export default {
    props: ['value', 'label'],
    data: function () {
      return {
        loading : true,
        availableVideos : [],
        modalActive: false
      }
    },
    computed: {
      readyVideos() { 
        return this.availableVideos.filter(v => v.status == 'ready')
      }
    },
    methods: {
      closeModal: function() {
        this.modalActive = false;
      },
      openModal: function() {
        this.modalActive = true;
      },
      setImage: function(url) {
        this.closeModal();
        this.$emit('input', url)
      },
      loadVideoFiles() {

        this.$axios.get(process.env.VUE_APP_FILE_API + '/files')
        .then(r => {
          this.availableVideos = r.data.payload.videos;
        })
        .catch(err => {
          console.log(err.message);
        })
        .finally(() => {
          this.loading = false;
        })

      }
    },
    mounted() {
      this.loadVideoFiles();
    }
  }

</script>

<style scoped>

  .grid-card {
    border-radius: 5px;
    background:rgba(0,0,0,0.05);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  .selected-image {
    border-radius: 5px;
    background:rgba(0,0,0,0.05);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  .selected-image img, .grid-card img {
    object-fit:cover;
    cursor: pointer;
  }

  .selected-image img:hover, .grid-card img:hover {
    opacity: 0.9
  }

  .video-box {
    cursor: pointer;
  }

  .video-box:hover {
    background : rgba(0,0,0,0.1)
  }
</style>
