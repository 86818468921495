<template>
  <div>

    <section class="section">
      <div class="container">
        <h1 class="title">👋</h1>
        <h2 class="subtitle">
          Hallo {{ displayName }} ! <br>Willkommen im <strong>ois media portal</strong>.
        </h2>
      </div>
    </section>

    <section class="section">
      <div class="container">

        <!-- <div class="box" style="max-width : 800px">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <p>
                  <strong></strong>
                  <br>

                </p>
              </div>
            </div>
          </article>
        </div> -->

      </div>
    </section>



  </div>
</template>

<script>
  export default {
    data() {
      return {
        
      }
    },
    computed : {
      displayName () { return this.$store.state.user.displayname }
    }
  }
</script>

<style scoped>
</style>
