<template lang="pug">
div
  h1.title Start
</template>

<script>
  export default {
    data: function () {
      return {

      }
    }
  }
</script>

<style>
</style>
