<template>
  <div id="login">
    <div class="grid-container">
      <div class="Left is-vertical-center">

        <div class="login-form">
          <figure class="image logo" style="margin-bottom:30px">
            <img class="" src="/assets/logo_dark.png" >
          </figure>
          <div class="content">
            <h1 class="title has-text-light">Bei Ihrem Konto anmelden</h1>
            <p class="help" style="margin-top:-15px">
              <span class="has-text-light">oder ein </span>
              <span> <a href="/signup" class="has-text-danger">neues Konto erstellen</a>.</span>
            </p>
          </div>
          <form v-on:submit.prevent style="margin-bottom:13px">
            <div class="field">
              <label class="label has-text-light">Benutzerdaten</label>
            </div>
            <div class="field">
              <p class="control has-icons-left has-icons-right">
                <input class="input" type="email" placeholder="E-Mail" v-model="email">
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i>
                </span>
                <span class="icon is-small is-right">
                  <i class="fas fa-check"></i>
                </span>
              </p>
            </div>
            <div class="field">
              <p class="control has-icons-left">
                <input class="input" type="password" placeholder="Passwort" v-model="password">
                <span class="icon is-small is-left">
                  <i class="fas fa-lock"></i>
                </span>
              </p>
            </div>
            <div class="field" style="margin-top: 30px;">
              <div class="control">
                <div class="columns">
                  <div class="column">
                    <button
                    type="submit"
                    class="button is-danger is-fullwidth"
                    :class="{'is-loading' : loggingIn}"
                    @click="loginUser">
                      <strong>Login</strong>
                    </button>
                  </div>
                  <div class="column">
                    <a
                    class="button is-danger is-outlined is-fullwidth"
                    :class="{'is-loading' : loggingIn}"
                    href="/signup"
                    >
                      <strong>Neues Konto</strong>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="Right plants">

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {
        email: '',
        password: '',
      }
    },
    computed: {
      loggingIn () {
        return this.$store.getters['authentication/loggingIn']
      }
    },
    methods: {
      loginUser: function () {
        const { email, password } = this;
        if (this.validateInput()) {
          this.$store.dispatch('authentication/AUTH_REQUEST', {email, password})
        } else {
          this.$store.dispatch('alert/ALERT_ERROR', 'Email oder Passwort nicht angegeben !');
        }
      },
      validateInput: function() {
        const { email, password } = this;
        return email.length > 0 && password.length > 0
          ? true
          : false;
      }
    },
    mounted: function () {
      this.$store.dispatch('authentication/AUTH_LOGOUT')
    }
  }
</script>

<style scoped>

  .signup-link {
    color: seagreen
  }

  .signup-link:hover {
    color: darkgreen
  }

  .is-vertical-center {
    display:flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:2% 20%;
  }

  .plants {
    background:url('https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "Left Right";
    min-height:100vh;
  }
  .Left { grid-area: Left; background:#1E1E1E;}
  .Right { grid-area: Right; }

  .logo img {

  }

</style>
