
import axios from 'axios';

const FETCH           = 'FETCH';
const UNCLAIM         = 'UNCLAIM';
const REMOVE          = 'REMOVE';
const CHECKIFONLINE   = 'CHECKIFONLINE';
const GETSYSTEMSTATUS = 'GETSYSTEMSTATUS';
const REMOVE_PLAYER_FROM_STORE = 'REMOVE_PLAYER_FROM_STORE';
const SENDMESSAGE     = 'SENDMESSAGE';
const SAVECHANNEL     = 'SAVECHANNEL';
const SAVEREGION      = 'SAVEREGION';
const SAVENAME        = 'SAVENAME';
const SAVEALERTSETTING = 'SAVEALERTSETTING';
const SAVE            = 'SAVE';
const STORE           = 'STORE';
const IS_LOADING      = 'IS_LOADING';
const IS_MODIFIED     = 'IS_MODIFIED';
const CREATECLAIM     = 'CREATECLAIM'

export const players = {
  namespaced: true,
  state: {
    available: [],
    ////////////////////////
    isLoading : false
  },
  actions: {
    [FETCH] : ({ commit, dispatch, rootGetters }) => {

      commit(IS_LOADING, true);
      axios.get(process.env.VUE_APP_JUPITER_API + '/user/players')
      .then( r => {
        if (r.data && r.data.payload && r.data.payload.players) {
          commit(STORE, r.data.payload.players);
        }
      })
      .catch(err => {
        dispatch('alert/ALERT_ERROR', err.message, {root:true});
      })
      .finally(() => {
        commit(IS_LOADING, false);
      });

    },

    [REMOVE] : ({ commit, dispatch, rootGetters }, playerid) => {

      commit(IS_LOADING, true);
      axios.post(process.env.VUE_APP_JUPITER_API + '/players/' + playerid + '/unclaim', {})
      .then(r => {
        if (r && r.data && r.data.status == 'ok') {
          commit(REMOVE_PLAYER_FROM_STORE, playerid);
        }
      })
      .catch(err => {
        dispatch('alert/ALERT_ERROR', err.message, {root:true});
      })
      .finally(() => {
        commit(IS_LOADING, false);
      });

    },

    [SENDMESSAGE] : ({ commit, dispatch, rootGetters}, {playerid, name, data}) => {
      return new Promise((resolve, reject) => {

        const message = {
          type: 'action',
          name: name,
          data: data
        };

        axios.post(process.env.VUE_APP_MEDOOZA_API + '/players/' + playerid + '/message', message)
        .then(r => {
          if (r.data && r.data.status == "error") {
            return resolve(false);
          } else {
            return resolve(true);
          }
        })
        .catch(err => {
          dispatch('alert/ALERT_ERROR', err.message, {root:true});
          return resolve(false);
        });

      });
    },

    [SAVECHANNEL] : ({commit, dispatch, rootGetters}, {playerid, channelid}) => {
      return new Promise((resolve, reject) => {

        axios.put(process.env.VUE_APP_JUPITER_API + '/user/players/' + playerid, {channel: channelid})
        .then(r => {
          dispatch(FETCH);
          dispatch(SENDMESSAGE,{
            playerid,
            name: 'reload',
            data: {}
          });
          return resolve(true);
        })
        .catch(err => {
          dispatch('alert/ALERT_ERROR', err.message, {root:true});
          return resolve(false);
        });

      });
    },

    [SAVEREGION] : ({commit, dispatch, rootGetters}, {playerid, parentOrganization}) => {
      return new Promise((resolve, reject) => {

        axios.put(process.env.VUE_APP_JUPITER_API + '/user/players/' + playerid, {parentOrganization: parentOrganization})
        .then(r => {
          dispatch(FETCH);
          dispatch(SENDMESSAGE,{
            playerid,
            name: 'reload',
            data: {}
          });
          return resolve(true);
        })
        .catch(err => {
          dispatch('alert/ALERT_ERROR', err.message, {root:true});
          return resolve(false);
        });

      });
    },

    [SAVENAME] : ({commit, dispatch, rootGetters}, {playerid, newname}) => {
      return new Promise((resolve, reject) => {

        axios.put(process.env.VUE_APP_JUPITER_API + '/user/players/' + playerid, {meta:{name:newname}})
        .then(r => {
          dispatch(FETCH);
          dispatch('alert/ALERT_SUCCESS', 'Player-Name wurd geändert', {root:true});
          return resolve(true);
        })
        .catch(err => {
          dispatch('alert/ALERT_ERROR', err.message, {root:true});
          return resolve(false);
        });

      });
    },

    [SAVEALERTSETTING] : ({commit, dispatch, rootGetters}, {playerid, active, email}) => {
      return new Promise((resolve, reject) => {

        axios.put(process.env.VUE_APP_JUPITER_API + '/user/players/' + playerid, {
          alert : {
            active : active,
            email  : email,
            lastNotification : null,
            lastHeartbeat : null
          }
        })
        .then(r => {
          dispatch(FETCH);
          dispatch('alert/ALERT_SUCCESS', active ? 'Benachrichtung wurde aktiviert' : 'Benachrichtung wurde deaktiviert', {root:true});
          return resolve(true);
        })
        .catch(err => {
          dispatch('alert/ALERT_ERROR', err.message, {root:true});
          return resolve(false);
        });

      });
    },

    [CREATECLAIM] : ({commit, dispatch, rootGetters}, shortid) => {
      return new Promise((resolve, reject) => {

        // axios.post('http://localhost:5050/api/v2' + '/players/' + shortid + '/claim', {})
        axios.post(process.env.VUE_APP_JUPITER_API + '/players/' + shortid + '/claim', {})
        .then(r => {
          dispatch(FETCH);
          dispatch('alert/ALERT_SUCCESS', `Player [${shortid}] wurde mit deinem Account verknüpft`, {root:true});
          return resolve(true);
        })
        .catch(err => {
          dispatch('alert/ALERT_ERROR', err.message, {root:true});
          return resolve(false);
        });

      });
    },

    [CHECKIFONLINE] : ({ commit, dispatch, rootGetters}, {playerid}) => {
      return new Promise((resolve, reject) => {

        axios.get(process.env.VUE_APP_MEDOOZA_API + '/players/' + playerid)
        .then(r => {
          if (r.data && r.data.status == "error") {
            return resolve(false);
          } else {
            return resolve(true);
          }
        })
        .catch(err => {
          dispatch('alert/ALERT_ERROR', err.message, {root:true});
          return resolve(false);
        });

      });
    },

    [GETSYSTEMSTATUS] : ({ commit, dispatch, rootGetters}, {playerid}) => {
      return new Promise((resolve, reject) => {

        const message = {
          type: 'action',
          name: 'systemstatus',
          data: {}
        };

        axios.post(process.env.VUE_APP_MEDOOZA_API + '/players/' + playerid + '/message', message)
        .then(r => {
          if (r.data && r.data.payload) {
            return resolve(r.data.payload)
          } else {
            return resolve({})
          }
        })
        .catch(err => {
          dispatch('alert/ALERT_ERROR', err.message, {root:true});
          return resolve(false);
        });

      });
    }

  },
  mutations: {
    [IS_LOADING] : (state, bool) => {
      state.isLoading = bool;
    },
    [STORE] : (state, players) => {
      state.available = players;
    },
    [REMOVE_PLAYER_FROM_STORE] : (state, idOfRemovePlayer) => {
      const index = state.available.findIndex(player => player._id === idOfRemovePlayer);
      state.available.splice(index, 1);
    }
  },
  getters: {

  }
}
