<template>
<div>

  <div class="grid-container">
    <div class="Left is-vertical-center" style="margin-bottom:100px;">

      <figure class="image logo">
        <img class="" src="/assets/logo.png" >
      </figure>
      <div class="content">
        <h1 class="title">Registrieren Sie sich</h1>
        <p class="help" style="margin-top:-15px">
          <span>oder </span>
          <span> <a href="/login" class="has-text-primary">melden Sie sich bei Ihrem Konto an</a>.</span>
        </p>
      </div>
      <form class="form" v-on:submit.prevent style="margin-bottom:30px;">
        <div class="field">
          <label class="label">E-Mail</label>
          <div class="control">
            <input class="input" type="email" placeholder="Ihre E-Mail Adresse" v-model="email">
          </div>
          <p class="help has-text-danger">{{ inputerror.email }}</p>
        </div>
        <div class="field">
          <label class="label">Passwort</label>
          <div class="control">
            <input class="input" type="password" v-model="password">
          </div>
          <p class="help has-text-danger">{{ inputerror.password }}</p>
        </div>
        <div class="field">
          <label class="label">Passwort wiederholen</label>
          <div class="control">
            <input class="input" type="password" v-model="passwordMatch">
          </div>
          <p class="help has-text-danger">{{ inputerror.passwordMatch }}</p>
        </div>

        <div class="columns">
          <div class="column">
            <label class="label">Username</label>
            <div class="field has-addons">
              <p class="control">
                <a class="button is-static">@</a>
              </p>
              <p class="control">
                <input class="input" type="text" placeholder="username" v-model="usernameInput">
              </p>
            </div>
            <p class="help has-text-danger">{{ inputerror.username }}</p>
          </div>
          <div class="column">
            <label class="label">Anzeigename</label>
            <div class="field">
              <div class="control">
                <input class="input" type="text" placeholder="User Name GMBH" v-model="displayname">
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <label class="label">Firma</label>
            <div class="field has-addons">
              <p class="control">
                <a class="button is-static">@</a>
              </p>
              <p class="control">
                <input class="input" type="text" placeholder="username" v-model="usernameInput">
              </p>
            </div>
            <p class="help has-text-danger">{{ inputerror.username }}</p>
          </div>
          <div class="column">
            <label class="label">UID-Nummer</label>
            <div class="field">
              <div class="control">
                <input class="input" type="text" placeholder="User Name GMBH" v-model="displayname">
              </div>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Tourismus Region</label>
          <div class="control">
            <div class="select is-primary is-fullwidth">
              <select v-model="organization">
                <option>Wolfgangsee Tourismus Gesellschaft</option>
              </select>
            </div>
          </div>
          <p class="help has-text-danger">{{ inputerror.organization }}</p>
        </div>
      </form>
      <div class="field">
        <label class="checkbox">
          <input type="checkbox" v-model="confirmAGB">
          <p class="help">
            <span>Ich habe die </span>
            <span> <a href="/agb" class="login-link">ois-media AGB</a> gelesen, verstanden und akzeptiert.</span>
          </p>
        </label>
        <p class="help has-text-danger">{{ inputerror.confirmAGB }}</p>
      </div>
      <a
        class="button is-primary is-fullwidth"
        @click="signupUser()"
        :class="{'is-loading' : signupInProcess}">
        <strong>Registrieren</strong>
      </a>
    </div>
    <div class="Right plants"></div>
  </div>
</div>
</template>

<script>
  export default {
    data: function () {
      return {
        email: '',
        password: '',
        passwordMatch: '',
        displayname: '',
        username: '',
        organization: '',
        confirmAGB: false,
        availableOrganizations: [],
        inputerror: {
          email: '',
          password: '',
          passwordMatch: '',
          organization: '',
          confirmAGB: ''
        }
      }
    },
    computed: {
      signupInProcess () {
        return this.$store.getters['user/isLoading']
      },
      usernameInput: {
        get() {
          return this.username;
        },
        set(nV) {
          this.username = nV.split(" ").join("").toLowerCase();
        }
      }
    },
    methods: {
      signupUser() {
        this.resetValidateInput();
        if (!this.validateInput()) { return }
        this.$store.dispatch('user/USER_SIGNUP', {
          email         : this.email,
          password      : this.password,
          displayname   : this.displayname,
          username      : this.username,
          //organization  : organization
        });
      },
      validateInput() {
        const { email, password, passwordMatch, username, displayname, organization, confirmAGB } = this;

        const vemail         = email.length > 0           ? true : false;
        const vpassword      = password.length > 0        ? true : false;
        const vpasswordMatch = password == passwordMatch  ? true : false;
        const vusername      = username.length > 0        ? true : false;
        const vorganization  = organization.length > 0    ? true : false;
        const vconfirmAGB    = confirmAGB                 ? true : false;

        this.inputerror = {
          email         : !vemail ?   'Bitte geben Sie Ihre E-Mail Adresse an !' : '',
          password      : !vpassword ? 'Passwort darf nicht leer sein!': '',
          passwordMatch : !vpasswordMatch ? 'Das Passwort stimmt nicht überein!' : '',
          username      : !vusername ? 'Username darf nicht leer sein!' : '',
          organization  : !vorganization ? 'Bitte wählen Sie eine Organisation!' : '',
          confirmAGB    : !vconfirmAGB ? 'Sie müssen unsere AGB akzeptieren!' : ''
        }

        return vemail && vpassword && vpasswordMatch && vusername && vorganization && vconfirmAGB ? true : false;

      },
      resetValidateInput() {
        this.inputerror = {
          email         : '',
          password      : '',
          passwordMatch : '',
          organization  : '',
          username      : '',
          confirmAGB    : ''
        }
      }
    },
    mounted: function () {
      this.$store.dispatch('authentication/AUTH_LOGOUT')
    }
  }
</script>

<style scoped>

  .login-link {
    color: seagreen
  }

  .login-link:hover {
    color: darkgreen
  }

  .is-vertical-center {
    display:flex !important;
    flex-direction: column;
    justify-content: center;
    padding:2% 20%;
  }

  .plants {
    background-repeat: no-repeat;
    background-size: cover;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "Left Right";
    min-height:100vh;
  }
  .Left { grid-area: Left; background:#F1F1F1;}
  .Right { grid-area: Right; }

  .logo {
    margin-bottom:30px;
    margin-top:100px;
  }

</style>
