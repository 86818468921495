<template lang="pug">
.field.is-horizontal
  label.label.field-label {{ label }}
  .control.field-body
    .select
      select(v-on:change="updateParent" v-model="input")
        option(value="cover") Proportional Gefüllt
        option(value="repeat") Originalgröße wiederholt
</template>

<script>

  export default {
    props: ['fill'],
    data: function () {
      return {
        label: "Hintergrund Füllung",
        input: "cover"
      }
    },
    methods: {
      updateParent: function() {
        this.$emit('set-backgroundFill', this.input)
      }
    },
    watch: {
      fill: function(val) {
        this.input = val;
      }
    }
  }

</script>

<style>
</style>
