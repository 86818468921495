<template>
  <div class="">

    <div
      class="section is-paddingless has-text-right"
      style="margin-bottom:10px"
      v-if="availableAssets.length > 0">
      <div class="buttons is-right">
        <a
          class="button is-black"
          @click="createNewAsset"
          :class="{'is-loading':isCreatingNewAsset}">
          <span class="icon"><i class="fas fa-plus"></i></span>
          <strong>{{ string.addbutton[assetType] }}</strong>
        </a>
        <a
          v-if="assetType === 'page'"
          class="button is-link"
          @click="createNewAssetFromTemplate"
          :class="{'is-loading':isCreatingNewAsset}"
          disabled>
          <span class="icon"><i class="fas fa-plus"></i></span>
          <strong>Von Vorlage erstellen</strong>
        </a>
      </div>
    </div>

    <template v-if="!isLoading">

      <template v-if="availableAssets.length > 0">

        <!-- All assets the user owns -->
        <div class="asset-list-top">
          <p class="title is-5">{{string.title[assetType]}}</p>
          <p class="subtitle is-7">Deine persönlichen Inhalte. Du kannst sie bearbeiten,<br>löschen und ander User hinzufügen.</p>
          <asset-list-table :availableAssets="availableAssets" :options="{edit:true, delete:true, mobilepage:assetType == 'mobile-page'}"/>
        </div>

        <hr>

        <!-- All assets the user is member of -->
        <div class="asset-list-bottom">
          <p class="title is-5">{{string.title2[assetType]}}</p>
          <p class="subtitle is-7">Du bist Mitglied dieser Inhalte. Du kannst sie<br>bearbeiten aber nicht löschen.</p>
          <asset-list-table :availableAssets="availableMemberAssets" :options="{edit:true, delete:false, mobilepage:assetType == 'mobile-page'}"/>
        </div>

      </template>
      <template v-else>
        <div class="section" style="display:flex; flex-direction:column; align-items:center;">
          <a
            class="button is-outlined is-black"
            @click="createNewAsset"
            :class="{'is-loading':isCreatingNewAsset}"
            style="margin-bottom:100px">
            <span class="icon"><i class="fas fa-plus"></i></span>
            <strong>{{ string.addbutton2[assetType] }}</strong>
          </a>
          <div class="figure">
            <img class="" src="/assets/women_grass_tree.png">
          </div>
        </div>
      </template>

    </template>
    <loading-indicator v-else />

  </div>

</template>

<script>

  import LoadingIndicator  from '@/components/LoadingIndicator';
  import AssetListTable    from '@/components/AssetListTable';

  export default {
    props: ["assetType"],
    data: function () {
      return {
        noDataNotification: 'Keine Daten',
        isCreatingNewAsset: false,
        hasNewAsset: false,
        string: {
          addbutton: {
            channel: "Neuen Channel erstellen",
            'mobile-page' : "Neue Mobile Seite erstellen",
            page   : "Neue Seite erstellen",
            module : "Neues Modul erstellen"
          },
          addbutton2: {
            channel: "Erstelle deinen ersten Channel",
            'mobile-page' : "Erstelle deine erste Mobile Seite",
            page   : "Erstelle deine erste Seite",
            module : "Erstelle dein erstes Modul"
          },
          title : {
            channel : "Deine Channel",
            page    : "Deine Seiten",
            'mobile-page' : "Deine Mobilen Seiten",
            module  : "Deine Module"
          },
          title2 : {
            channel : "Mitglieds-Channel",
            page    : "Mitglieds-Seiten",
            'mobile-page' : "Mitglieds-Mobile-Seiten",
            module  : "Mitglieds-Module"
          }
        }
      }
    },
    computed: {
      availableAssets()       { return this.$store.getters["assets/" + this.assetType + "s"] },
      availableMemberAssets() { return this.$store.getters["assets/" + this.assetType + "sMember"] },
      isLoading()       { return this.$store.state.assets.assetsLoading },
    },
    components : {
      LoadingIndicator,
      AssetListTable
    },
    methods: {
      createNewAsset: function () {
        this.isCreatingNewAsset = true;
        this.$store.dispatch("assets/NEW_ASSET", this.assetType)
        .then((newasset) => {
          this.isCreatingNewAsset = false;
          if (newasset) { this.hasNewAsset = true; }
        });
      },
      createNewAssetFromTemplate() {

      }
    }
  }
</script>

<style scoped>

  .asset-list-top {
    margin-bottom:50px;
  }

  .asset-list-bottom {
    margin-top:50px;
    margin-bottom:50px;
  }

</style>
