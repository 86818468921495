<template>
  <div>

    <div class="columns">

      <!-- PLACEHOLDER SYMBOLE -->
      <div class="column">
        <div class="field">
          <label class="label">Trenner Symbol wählen</label>
          <div class="control">
            <div class="select">
              <select v-model="symbole" @change="update">
                <option>❤</option>
                <option>◻</option>
                <option>✖</option>
                <option>➕</option>
                <option>⚫</option>
                <option>🔹</option>
                <option>🔷</option>
                <option>⭕</option>
                <option>☑</option>
                <option>🔅</option>
                <option>⚠</option>
                <option>♦</option>
                <option>/</option>
                <option>//</option>
                <option>///</option>
                <option>|</option>
                <option>-</option>
              </select>
            </div>
          </div>
          <p class="help" style="margin-bottom:5px;">Welches Symbol soll zwichen den Texten angezeigt werden?</p>
        </div>
      </div>
      <!--            -->

      <!-- SPEED -->
      <div class="column">
        <div class="field">
          <label class="label">Geschwindigkeit</label>
          <div class="control">
            <input v-model.number="speed" type="number" class="input" @change="update"/>
          </div>
          <p class="help" style="margin-bottom:5px;">Je kleiner die Zahl, desto schneller fließt der Text</p>
        </div>
      </div>

      <!-- TEXT SIZE -->
      <div class="column">
        <div class="field">
          <label class="label">Text Größe</label>
          <div class="control">
            <input v-model.number="size" type="number" class="input" @change="update" />
          </div>
          <p class="help" style="margin-bottom:5px;">Je kleiner die Zahl, desto schneller fließt der Text</p>
        </div>
      </div>

    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">Text Farbe</label>
          <input type="color" class="input" v-model="color" @change="update" />
        </div>
      </div>
    </div>
    <!-- /////////////////////////////////////////////////////////////////// -->

    <div class="columns">
      <div class="column">

        <div class="field">
          <label class="label">Text eingeben</label>
          <div class="control">
            <textarea class="textarea is-primary" placeholder="Text" v-model="textPiecesRaw" @change="update"></textarea>
          </div>
        </div>

        <div class="field">
          <label class="label">Text Bausteine</label>
          <p class="textpiece" v-for="(text, index) in textPieces" :key="index">{{ text }}</p>
        </div>

      </div>
    </div>

    <div class="marquee-container" :style="{
        fontSize : `${size}px`,
        color : color
      }">
      <marquee-text :duration="speed" :repeat="10" :key="changeKey">
        {{ textPieces.join(" " + `${symbole}` + " ") }} {{ symbole }} ‎ ‎
      </marquee-text>
    </div>
    

  </div>
</template>

<script>

  import MarqueeText from 'vue-marquee-text-component'

  export default {
    props: ['value'],
    data() {
      return {
        textPieces        : this.value.textPieces         ? this.value.textPieces         : ["Das ist die erste Nachricht", "Das ist ist zweite Nachricht"],
        symbole           : this.value.symbole            ? this.value.symbole            : '🔹',
        speed :             this.value.speed              ? this.value.speed              : 30,
        size :              this.value.size               ? this.value.size               : 15,
        color :             this.value.color              ? this.value.color              : '#00000',
        changeKey : 0,
      }
    },
    components : {
      MarqueeText
    },
    computed : {
      textPiecesRaw : {
        get() { return this.textPieces.join("\n") },
        set(v) { 
          this.textPieces = v.split("\n");
        }
      }
    },
    methods: {
      update() {
        this.changeKey++;
        this.$emit('input', {
          textPieces        : this.textPieces,
          symbole           : this.symbole,
          speed             : this.speed,
          size              : this.size,
          color             : this.color
        });
      }
    },
    mounted() {
      this.update();
    }
  }

</script>

<style scoped>


.textpiece {
  background : rgba(255,255,255,0.1);
  margin-bottom : 10px;
  padding : 5px;
  border-radius:2px;
}

.marquee-container {
  width : 700px;
  padding : 20px;
  background : white;
  white-space: no-wrap;
}
</style>
