import { router } from '@/router.js';
import axios from 'axios';

const USER_FETCH           = 'USER_FETCH';
const USER_SAVE            = 'USER_SAVE';
const USER_STORE           = 'USER_STORE';
const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD';
const USER_SIGNUP          = 'USER_SIGNUP';
const USER_LOADING         = 'USER_LOADING';
const USER_MODIFIED        = 'USER_MODIFIED';
const UPDATE_USERNAME      = 'UPDATE_USERNAME';
const UPDATE_DISPLAYNAME   = 'UPDATE_DISPLAYNAME';

export const user = {
  namespaced: true,
  state: {
    _shortid           : "",
    _id                : "",
    email              : "",
    displayname        : "",
    parentOrganization : {},
    username           : "",
    createdAt          : "",
    permissionRole     : 0,
    permissionLevel    : 0,
    notifications      : [],
    ////////////////////////
    isLoading : false,
    isModified  : false,
  },
  actions: {

    /* ++++++++++++++++++++++++++++++++++++++++++ */
    /* VUEX ACTION TO GET USER DATA FROM DATABASE */
    /* ++++++++++++++++++++++++++++++++++++++++++ */
    [USER_FETCH] : ({ commit, dispatch, rootGetters }) => {

      commit(USER_LOADING, true);
      axios.get(process.env.VUE_APP_JUPITER_API + '/user')
      .then(r => {
        commit(USER_STORE, r.data.payload.user);
        commit(USER_MODIFIED, false);
        commit(USER_LOADING, false);
      })
      .catch(err => {
        commit(USER_LOADING, false);
        dispatch('alert/ALERT_ERROR', err.message, {root:true});
        dispatch('auth/AUTH_LOGOUT', null, {root:true});
        router.push('/login');
      });

    },

    /* ++++++++++++++++++++++++++++++++++++++++++ */
    /* VUEX ACTION TO SAVE USER DATA TO DATABASE  */
    /* ++++++++++++++++++++++++++++++++++++++++++ */
    [USER_SAVE] : ({ commit, dispatch, rootGetters }) => {

      commit(USER_LOADING, true);
      const payload = rootGetters['user/toSave'];
      axios.put(process.env.VUE_APP_JUPITER_API + '/user', payload)
      .then(r => {
        dispatch('alert/ALERT_SUCCESS', "Benutzer erfolgreich gespeichert", {root:true});
        commit(USER_MODIFIED, false);
      })
      .catch(err => {
        dispatch('alert/ALERT_ERROR', err.message, {root:true});
      })
      .finally(() => {
        commit(USER_LOADING, false);
      });;

    },

    /* ++++++++++++++++++++++++++++++++++++++++++ */
    /* VUEX ACTION TO CHANGE USER PASSWORD        */
    /* ++++++++++++++++++++++++++++++++++++++++++ */
    [USER_CHANGE_PASSWORD] : ({ commit, dispatch, rootGetters}, newpassword) => {

      commit(USER_LOADING, true);
      const payload = { password : newpassword };
      axios.put(process.env.VUE_APP_JUPITER_API + '/user', payload, options)
      .then(r => {
        dispatch('alert/ALERT_SUCCESS', "Passwort erfolgreich geändert", {root:true});
      })
      .catch(err => {
        dispatch('alert/ALERT_ERROR', err.message, {root:true});
      })
      .finally(() => {
        commit(USER_LOADING, false);
      });

    },

    [USER_SIGNUP] : ({ commit, dispatch, rootGetters}, payload) => {

      commit(USER_LOADING, true);
      axios.post(process.env.VUE_APP_JUPITER_API + '/user', payload)
      .then(r => {
        dispatch('alert/ALERT_SUCCESS', "Benutzer wurde erfolgreich erstellt", {root:true});
      })
      .catch(err => {
        dispatch('alert/ALERT_ERROR', err.message, {root:true});
      })
      .finally(() => {
        commit(USER_LOADING, false)
      });

    }

  },
  mutations: {

    /* ++++++++++++++++++++++++++++++++++++++++++ */
    /* VUEX MUTATION TO SET USER DATA TO STORE    */
    /* ++++++++++++++++++++++++++++++++++++++++++ */
    [USER_STORE] : (state, user) => {
      state._shortid    = user._shortid;
      state._id         = user._id;
      state.email       = user.email;
      state.displayname = user.displayname;
      state.username    = user.username;
      state.createdAt   = user.createdAt;
      state.parentOrganization = user.parentOrganization;
      state.permissionRole     = user.permission.role;
      state.permissionLevel    = user.permission.level;
      state.notifications      = user.notifications;
    },

    /* +++++++++++++++++++++++++++++++++++++++++++ */
    /* VUEX MUTATION TO SET SINGLE VALUES IN STATE */
    /* +++++++++++++++++++++++++++++++++++++++++++ */
    [UPDATE_DISPLAYNAME] : (state, string) => {
      state.displayname = string;
      state.isModified = true;
    },
    [UPDATE_USERNAME] : (state, string) => {
      state.username = string;
      state.isModified = true;
    },

    /* ++++++++++++++++++++++++++++++++++++++++++ */
    /* VUEX MUTATION TO SET USER LOADING STATUS   */
    /* ++++++++++++++++++++++++++++++++++++++++++ */
    [USER_LOADING] : (state, bool) => {
      state.isLoading = bool;
    },
    [USER_MODIFIED] : (state, bool) => {
      state.isModified = bool;
    }

  },
  getters: {
    _id: (state) => {
      return state._id;
    },
    notifications: (state) => {
      return state.notifications;
    },
    complete: (state) => {
      return {
        _shortid           : state._shortid,
        _id                : state._id,
        email              : state.email,
        displayname        : state.displayname,
        username           : state.username,
        createdAt          : state.createdAt,
        parentOrganization : state.parentOrganization,
        permissionRole     : state.permissionRole,
        permissionLevel    : state.permissionLevel,
        notifications      : state.notifications
      }
    },
    toSave: (state) => {
      return {
        email              : state.email,
        displayname        : state.displayname,
        username           : state.username,
        parentOrganization : state.parentOrganization
      }
    }
  }
}
