<template>
  <div class="columns">

    <!-- /////////////////////////////////////////////////////////////////// -->

    <div class="column">

      <!-- SHOW DATE -->
      <div class="field is-horizontal">
        <label class="label field-label">Datum anzeigen</label>
        <div class="control field-body">
          <input type="checkbox" v-model="showDate" v-on:change="update" />
        </div>
      </div>
      <!--           -->

      <!-- SHOW TIME -->
      <div class="field is-horizontal">
        <label class="label field-label">Uhr anzeigen</label>
        <div class="control field-body">
          <input type="checkbox" v-model="showTime" v-on:change="update" />
        </div>
      </div>
      <!--           -->

      <!-- SET CLOCKTYPE -->
      <div class="field">
        <label class="label">Uhr Typ</label>
        <div class="select">
          <select v-model="clockType" v-on:change="update">
            <option value="digital">Digital</option>
          </select>
        </div>
      </div>
      <!--           -->

    </div>

    <!-- /////////////////////////////////////////////////////////////////// -->

  </div>
</template>

<script>

  export default {
    props: ['value'],
    data() {
      return {
        showDate : this.value.showDate != null  ? this.value.showDate : true,
        showTime : this.value.showTime  != null ? this.value.showTime : true,
        clockType: this.value.clockType ? this.value.clockType : 'digital'
      }
    },
    methods: {
      update() {
        console.log(this.clockType);
        console.log(this.showDate);
        console.log(this.showTime);
        this.$emit('input', {
          showDate : this.showDate,
          showTime : this.showTime,
          clockType: this.clockType
        });
      }
    },
    mounted() {
      console.log(this.value);
    }
  }

</script>

<style scoped>
</style>
