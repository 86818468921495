<template lang="html">
  <div class="asset-list-table">

    <b-table :data="availableAssets" :narrowed="true" :hoverable="true" :striped="true" :checkable="true" >

      <b-table-column v-if="!options.mobilepage" field="colorlabel" label="" width="40px" v-slot="props" sortable >
        <b-icon icon="circle" size="is-small" :style="{color:`${props.row.meta.labelColor}`}"></b-icon>
      </b-table-column>

      <b-table-column v-if="options.mobilepage" field="_shortid" label="Link" v-slot="props" sortable searchable>
        <a :href="'https://mobile.ois-media.com/r/' + props.row._shortid">{{ 'https://mobile.ois-media.com/r/' + props.row._shortid }}</a>
      </b-table-column>

      <b-table-column v-else field="resource.module" label="Typ" v-slot="props" sortable searchable>
        {{ props.row.resource.module }}
      </b-table-column>

      <b-table-column v-if="!options.mobilepage" field="_shortid" label="ID"  v-slot="props" sortable searchable>
        {{ props.row._shortid }}
      </b-table-column>

      <b-table-column field="meta.name" label="Name"  v-slot="props" sortable searchable>
        {{ props.row.meta.name }}
      </b-table-column>

      <b-table-column field="updatedAt" label="Zuletzt editiert" v-slot="props" sortable>
        {{ convertToHumanReadableDate(props.row.updatedAt) }}
      </b-table-column>

      <b-table-column field="options" label=""  v-slot="props" >
        <div class="buttons actions">
          <button v-if="options.edit" class="button is-outlined is-link is-small" @click="openAssetToEdit(props.row._id, props.row.type)"><strong>Editor</strong></button>
          <button v-if="options.delete" class="button is-outlined is-danger is-small" @click="deleteAsset(props.row._id)" :class="{'is-loading' : isDeletingAsset}"><strong>Entfernen</strong></button>
        </div>
      </b-table-column>

    </b-table>

  </div>
</template>

<script>
  import moment from "moment";

  export default {
    props : ['availableAssets', 'options'],
    data() {
      return {
        isDeletingAsset: false,
      }
    },
    methods: {
      truncatedDesc: function(str) {
        return str.substring(0, 30) + '...'
      },
      deleteAsset: function(id) {
        this.isDeletingAsset = true;
        this.$store.dispatch("assets/REMOVE_ASSET", id)
        .then(r => {
          this.isDeletingAsset = false;
        });
      },
      openAssetToEdit: function (id, type) {
        let detype = type == 'channel'      ? 'channel'
                   : type == 'page'         ? 'seiten'
                   : type == 'mobile-page'  ? 'mobile-seiten'
                   : type == 'module'       ? 'module' : false;
        let url = detype ? "/dashboard/" + detype + "/edit/" + id : "/404";
        this.$router.push(url);
      },
      convertToHumanReadableDate(date) {
        return moment(date).fromNow();
        // return moment(date).format('DD.MM.YYYY, HH:mm:ss a');
      }
    }
  }
</script>

<style lang="css" scoped>
  tr {
    cursor: pointer;
  }

  tr .actions {
    visibility: hidden;
  }

  tr:hover .actions {
    visibility: visible;
  }
</style>
