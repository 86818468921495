export default {
  'standard' : {
    name: 'standard',
    columns: '1fr',
    rows: '1fr',
    template: '"layout-1"',
    areas: [
      "layout-1"
    ]
  },
  // 2 AREAS
  'layout-half-horizontal' : {
    name: 'layout-half-horizontal',
    columns: '1fr',
    rows: '1fr 1fr',
    template: '"layout-1" "layout-2"',
    areas: [
      "layout-1",
      "layout-2",
    ]
  },
  'layout-half-vertical' : {
    name: 'layout-half-vertical',
    columns: '1fr 1fr',
    rows: '1fr',
    template: '"layout-1 layout-2"',
    areas: [
      "layout-1",
      "layout-2",
    ]
  },
  // 3 AREAS
  'layout-half-with-double-right' : {
    name: 'layout-half-with-double-right',
    columns: '1fr 1fr',
    rows: '1fr 1fr',
    template: '"layout-1 layout-2" "layout-1 layout-3"',
    areas: [
      "layout-1",
      "layout-2",
      "layout-3"
    ]
  },
  'layout-half-with-double-left' : {
    name: 'layout-half-with-double-left',
    columns: '1fr 1fr',
    rows: '1fr 1fr',
    template: '"layout-1 layout-3" "layout-2 layout-3"',
    areas: [
      "layout-1",
      "layout-2",
      "layout-3"
    ]
  },
  'layout-half-with-double-top' : {
    name: 'layout-half-with-double-top',
    columns: '1fr 1fr',
    rows: '1fr 1fr',
    template: '"layout-1 layout-2" "layout-3 layout-3"',
    areas: [
      "layout-1",
      "layout-2",
      "layout-3"
    ]
  },
  'layout-half-with-double-bottom' : {
    name: 'layout-half-with-double-bottom',
    columns: '1fr 1fr',
    rows: '1fr 1fr',
    template: '"layout-1 layout-1" "layout-2 layout-3"',
    areas: [
      "layout-1",
      "layout-2",
      "layout-3"
    ]
  },
  // 4 AREAS
  'layout-thirds-with-double-middle' : {
    name: 'layout-thirds-with-double-middle',
    columns: '1fr 1fr 1fr',
    rows: '1fr 1fr',
    template: '"layout-1 layout-2 layout-4" "layout-1 layout-3 layout-4"',
    areas: [
      "layout-1",
      "layout-2",
      "layout-3",
      "layout-4"
    ]
  },
  // 6 AREAS
  'joseph-rinaldi' : {
    name: 'joseph-rinaldi',
    columns: '1fr 1fr 1fr',
    rows: '1fr 1fr 1fr 1fr',
    template: `
      "layout-1 layout-3 layout-5"
      "layout-2 layout-3 layout-6"
      "layout-2 layout-3 layout-6"
      "layout-2 layout-4 layout-6"
    `,
    areas: [
      "layout-1",
      "layout-2",
      "layout-3",
      "layout-4",
      "layout-5",
      "layout-6"
    ]
  },
  // 6 AREAS
  'jarvis-tony' : {
    name: 'jarvis-tony',
    columns: '1fr 1fr 1fr',
    rows: '1fr 1fr 1fr 1fr',
    template: `
      "layout-1 layout-3 layout-5"
      "layout-2 layout-3 layout-6"
      "layout-2 layout-3 layout-7"
      "layout-2 layout-4 layout-7"
    `,
    areas: [
      "layout-1",
      "layout-2",
      "layout-3",
      "layout-4",
      "layout-5",
      "layout-6",
      "layout-7",
    ]
  },
  // 7 AREAS
  'jessica-davis' : {
    name: 'jessica-davis',
    columns: '1fr 1fr 1fr',
    rows: '1fr 1fr 1fr 1fr 1fr 1fr',
    template: `
      "layout-1 layout-1 layout-1"
      "layout-2 layout-4 layout-6"
      "layout-3 layout-4 layout-6"
      "layout-3 layout-5 layout-7"
      "layout-3 layout-5 layout-7"
      "layout-8 layout-8 layout-8"
      `,
    areas: [
      "layout-1",
      "layout-2",
      "layout-3",
      "layout-4",
      "layout-5",
      "layout-6",
      "layout-7",
      "layout-8"
    ]
  },
  // 7 AREAS
  'juliet-morris' : {
    name: 'juliet-morris',
    columns: '1fr 1fr 1fr 1fr',
    rows: '1fr 1fr 1fr 1fr 1fr 1fr',
    template: `
      "layout-1 layout-1 layout-1 layout-1"
      "layout-2 layout-4 layout-4 layout-5"
      "layout-2 layout-4 layout-4 layout-5"
      "layout-3 layout-4 layout-4 layout-6"
      "layout-3 layout-4 layout-4 layout-6"
      "layout-7 layout-7 layout-7 layout-7"
      `,
    areas: [
      "layout-1",
      "layout-2",
      "layout-3",
      "layout-4",
      "layout-5",
      "layout-6",
      "layout-7"
    ]
  },
  // 3 AREAS
  'julie-dean' : {
    name: 'julie-dean',
    columns: '1fr 1fr',
    rows: '1fr 1fr 1fr 1fr 1fr 1fr',
    template: `
      "layout-1 layout-1"
      "layout-2 layout-3"
      "layout-2 layout-3"
      "layout-2 layout-3"
      "layout-2 layout-3"
      "layout-2 layout-3"
      `,
    areas: [
      "layout-1",
      "layout-2",
      "layout-3"
    ]
  },
  // 5 AREAS
  'tori-black' : {
    name: 'tori-black',
    columns: '1fr 1fr',
    rows: '1fr 1fr 1fr 1fr 1fr 1fr',
    template: `
      "layout-1 layout-1"
      "layout-2 layout-4"
      "layout-2 layout-4"
      "layout-2 layout-4"
      "layout-3 layout-5"
      "layout-3 layout-5"
      `,
    areas: [
      "layout-1",
      "layout-2",
      "layout-3",
      "layout-4",
      "layout-5"
    ]
  },
  // 2 AREAS
  'danika-mori' : {
    name: 'danika-mori',
    columns: '1fr',
    rows: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    template: `
      "layout-1"
      "layout-2"
      "layout-2"
      "layout-2"
      "layout-2"
      "layout-2"
      "layout-2"
      "layout-2"
      "layout-2"
      "layout-2"
      "layout-2"
      "layout-2"
      "layout-2"
      "layout-2"
      `,
    areas: [
      "layout-1",
      "layout-2"
    ]
  },
  'cascudi-pen' : {
    name: 'cascudi-pen',
    columns: '1fr',
    rows: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    template: `
      "layout-1"
      "layout-1"
      "layout-1"
      "layout-1"
      "layout-1"
      "layout-1"
      "layout-1"
      "layout-1"
      "layout-1"
      "layout-1"
      "layout-1"
      "layout-1"
      "layout-1"
      "layout-2"
      `,
    areas: [
      "layout-1",
      "layout-2"
    ]
  },
  // 6 AREAS
  'cascudi-top' : {
    name: 'cascudi-top',
    columns: '1fr 1fr 1fr',
    rows: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    template: `
      "layout-1 layout-3 layout-5"
      "layout-1 layout-3 layout-5"
      "layout-1 layout-3 layout-5"
      "layout-1 layout-3 layout-5"
      "layout-2 layout-3 layout-6"
      "layout-2 layout-3 layout-6"
      "layout-2 layout-3 layout-6"
      "layout-2 layout-3 layout-6"
      "layout-2 layout-3 layout-6"
      "layout-2 layout-3 layout-6"
      "layout-2 layout-4 layout-6"
      "layout-2 layout-4 layout-6"
      "layout-2 layout-4 layout-6"
      "layout-7 layout-7 layout-7"
    `,
    areas: [
      "layout-1",
      "layout-2",
      "layout-3",
      "layout-4",
      "layout-5",
      "layout-6",
      "layout-7"
    ]
  },
  'cascudi-sobo' : {
    name: 'cascudi-sobo',
    columns: '1fr 1fr',
    rows: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    template: `
      "layout-1 layout-2" 
      "layout-1 layout-2" 
      "layout-1 layout-2" 
      "layout-1 layout-2" 
      "layout-1 layout-2" 
      "layout-1 layout-2" 
      "layout-1 layout-2" 
      "layout-1 layout-2" 
      "layout-1 layout-2"
      "layout-1 layout-2"
      "layout-1 layout-2"
      "layout-1 layout-2"
      "layout-1 layout-2"
      "layout-3 layout-3"
      `,
    areas: [
      "layout-1",
      "layout-2",
      "layout-3"
    ]
  },
   'salvadore-petrucci' : {
    name: 'salvadore-petrucci',
    columns: '1fr',
    rows: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    template: `
      "layout-1 layout-1" 
      "layout-1 layout-1" 
      "layout-1 layout-1" 
      "layout-2 layout-2" 
      "layout-2 layout-2" 
      "layout-2 layout-2" 
      "layout-2 layout-2" 
      "layout-2 layout-2" 
      "layout-2 layout-2" 
      "layout-3 layout-3"
      "layout-3 layout-3"
      "layout-3 layout-3"
      "layout-3 layout-3"
      "layout-3 layout-3"
      "layout-3 layout-3"
      "layout-4 layout-4"
      `,
    areas: [
      "layout-1",
      "layout-2",
      "layout-3",
      "layout-4"
    ]
  },
  'valentina-savage' : {
    name: 'valentina-savage',
    columns: '1fr',
    rows: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    template: `
      "layout-1 layout-1" 
      "layout-1 layout-1" 
      "layout-1 layout-1" 
      "layout-2 layout-2" 
      "layout-2 layout-2" 
      "layout-2 layout-2" 
      "layout-2 layout-2" 
      "layout-2 layout-2" 
      "layout-2 layout-2" 
      "layout-2 layout-2"
      "layout-2 layout-2"
      "layout-2 layout-2"
      "layout-3 layout-3"
      "layout-3 layout-3"
      "layout-3 layout-3"
      "layout-4 layout-4"
      `,
    areas: [
      "layout-1",
      "layout-2",
      "layout-3",
      "layout-4"
    ]
  },
  'anni-the-duck' : {
    name: 'anni-the-duck',
    columns: '1fr 1fr 1fr',
    rows: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    template: `
      "layout-1 layout-1 layout-1"
      "layout-1 layout-1 layout-1"
      "layout-1 layout-1 layout-1"
      "layout-1 layout-1 layout-1"
      "layout-2 layout-3 layout-4"
      "layout-2 layout-3 layout-4"
      "layout-2 layout-3 layout-4"
      "layout-2 layout-3 layout-4"
      "layout-2 layout-3 layout-4"
      "layout-2 layout-3 layout-4"
      "layout-2 layout-3 layout-4"
      "layout-2 layout-3 layout-4"
      "layout-2 layout-3 layout-4"
      "layout-5 layout-5 layout-5"
    `,
    areas: [
      "layout-1",
      "layout-2",
      "layout-3",
      "layout-4",
      "layout-5"
    ]
  }
}
