<template>
  <div class="field">
    <label class="label has-text-light">Label Farbe</label>
    <span class="icon" v-for="a in available">
      <i
        class="fas fa-circle"
        :style="{'color':a}"
        :class="[a === color ? 'selected' : '']"
        @click="updateParent(a)"
        >
      </i>
    </span>
  </div>
</template>

<script>

  export default {
    props:['value'],
    data: function () {
      return {
        color: this.value,
        available: [
          "#e53935",
          "#8E24AA",
          "#3949AB",
          "#039BE5",
          "#00897B",
          "#7CB342",
          "#FFB300",
          "#F4511E",
          "#555555"
        ]
      }
    },
    methods: {
      updateParent: function(newcolor) {
        this.color = newcolor;
        this.$emit("input",newcolor)
      }
    }
  }

</script>

<style scoped>
  .icon {
    cursor: pointer;
  }
  .icon:hover {
    border:5px solid rgba(255,255,255,0.8);
    border-radius:15px;
  }
  .selected {
    border:5px solid rgba(255,255,255,0.8);
    border-radius:15px;
  }
</style>
