<template>
  <div class="columns">

    <!-- //////////////////////////////////////////////////////////////////// -->

    <div class="column">

      <!-- Youtube ID -->
      <div class="field">
        <label class="label">Vimeo ID</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="t56JFBFFKUI"
            v-model="videoId"
            v-on:change="update" />
        </div>
      </div>
      <!--            -->

      <!-- Vimeo ID -->
      <div class="field">
        <label class="checkbox">
          <input
            type="checkbox"
            v-model="muted"
            v-on:change="update" />
          Muted
        </label>
      </div>
      <!--            -->

      <!-- Vimeo ID -->
      <div class="field">
        <label class="checkbox">
          <input
            type="checkbox"
            v-model="looped"
            v-on:change="update" />
          Looped
        </label>
      </div>
      <!--            -->

    </div>

    <!-- //////////////////////////////////////////////////////////////////// -->

    <div class="column">

      <!-- PREVIEW -->
      <div id="vimeo-preview-player"></div>

    </div>

  </div>
</template>

<script>

  import Vimeo from '@vimeo/player';

  export default {
    props: ['value'],
    data() {
      return {
        videoId : this.value.videoId ? this.value.videoId : '',
        muted : this.value.muted ? this.value.muted : true,
        looped : this.value.looped ? this.value.looped : true,
        player : null
      }
    },
    methods: {
      update() {
        this.$emit('input', {
          videoId : this.videoId,
          muted : this.muted,
          looped : this.looped
        });
      },
      createPlayer() {
        const options = {
          id : this.videoId,
          width : '100%',
          height : '100%',
          loop : this.looped,
          autoplay : true,
          muted : this.muted
        }

        const player = new Vimeo('vimeo-preview-player', options);
        player.setVolume(1);
        player.play();
        this.player = player;

      },
      updatePlayer() {

      },
      destroyPlayer() {

      }
    },
    mounted() {
      this.createPlayer();
    }
  }

</script>

<style scoped>

  #vimeo-preview-player {
    width : 100%;
    height : 100%;
  }
</style>
