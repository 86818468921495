import Vue from 'vue'
import Router from 'vue-router'

import Login  from './views/Login.vue'
import Signup from './views/Signup.vue'

import Dashboard        from './views/Dashboard.vue'
import DashboardStart   from './views/DashboardStart.vue'
import DashboardRight   from './components/DashboardRight.vue'

import ChangelogList    from './components/ChangelogList.vue'

import CatalogList      from './components/CatalogList.vue'

import AssetList        from './components/AssetList.vue'
import AssetEdit        from './components/AssetEdit.vue'
import ImageGrid        from './components/ImageGrid.vue'
import VideoGrid        from './components/VideoGrid.vue'

import ProfileDetails        from './components/ProfileDetails.vue'
import ProfilePlayers        from './components/ProfilePlayers.vue'
import ProfileOrganization   from './components/ProfileOrganization.vue'



import NOTFOUND from './views/NOTFOUND.vue'

Vue.use(Router);


/////////////////
// ROUTE GUARD //
/*
* to:   Object : the target Route Object being navigated to
* from: Object : the current route being navigated from
* next: Function : this function must be called to resolve the hook. The action
*       depends on the arguments provided to next :
*/

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/dashboard'
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      redirect: '/dashboard/start',
      children: [
        {
          path: 'start',
          name: 'dashboard_start',
          component: DashboardRight,
          redirect: '/dashboard/start/changelog',
          children: [
            {
              path : 'changelog',
              name: 'dashboard_start_changelog',
              component : ChangelogList
            }
          ]
        },
        {
          path: 'katalog',
          name: 'dashboard_katalog',
          component: DashboardRight,
          redirect: '/dashboard/katalog/liste',
          children: [
            {
              path: 'liste',
              name: 'dashboard_katalog_liste',
              component: CatalogList
            }
          ]
        },
        {
          path: 'channel',
          name: 'dashboard_channel',
          component: DashboardRight,
          redirect: '/dashboard/channel/liste',
          props: { showQuickMenu: true, quickMenuName: "channel" },
          children: [
            {
              path: 'liste',
              name: 'dashboard_channel_liste',
              component: AssetList,
              props: {
                assetType        : 'channel',
                assetTypeToLoad  : 'channel',
                loadPublicAssets : false,
                showDescription  : true
              }
            },
            {
              path: 'edit/:id',
              name: 'dashboard_channel_edit',
              component: AssetEdit,
              props: {
                assetType        : 'channel',
                assetTypeToLoad  : 'page',
                loadPublicAssets : true,
                showDescription  : false
              }
            }
          ]
        },
        {
          path: 'seiten',
          name: 'dashboard_seiten',
          component: DashboardRight,
          redirect: '/dashboard/seiten/liste',
          props: {showQuickMenu: true, quickMenuName: "seiten"},
          children: [
            {
              path: 'liste',
              name: 'dashboard_seiten_liste',
              component: AssetList,
              props: {
                assetType        : 'page',
                assetTypeToLoad  : 'page',
                loadPublicAssets : false,
                showDescription  : true
              }
            },
            {
              path: 'edit/:id',
              name: 'dashboard_seiten_edit',
              component: AssetEdit,
              props: {
                assetType        : 'page',
                assetTypeToLoad  : 'module',
                loadPublicAssets : true,
                showDescription  : false
              }
            }
          ]
        },
        {
          path: 'mobile-seiten',
          name: 'dashboard_mobile_seiten',
          component: DashboardRight,
          redirect: '/dashboard/mobile-seiten/liste',
          props: {showQuickMenu: true, quickMenuName: "mobile-seiten"},
          children: [
            {
              path: 'liste',
              name: 'dashboard_mobile_seiten_liste',
              component: AssetList,
              props: {
                assetType        : 'mobile-page',
                assetTypeToLoad  : 'mobile-page',
                loadPublicAssets : false,
                showDescription  : true
              }
            },
            {
              path: 'edit/:id',
              name: 'dashboard_mobile_seiten_edit',
              component: AssetEdit,
              props: {
                assetType        : 'mobile-page',
                assetTypeToLoad  : 'module',
                loadPublicAssets : true,
                showDescription  : false
              }
            }
          ]
        },
        {
          path: 'module',
          name: 'dashboard_module',
          component: DashboardRight,
          redirect: '/dashboard/module/liste',
          props: {showQuickMenu: true, quickMenuName: "module"},
          children: [
            {
              path: 'liste',
              name: 'dashboard_module_liste',
              component: AssetList,
              props: {
                assetType        : 'module',
                assetTypeToLoad  : 'module',
                loadPublicAssets : false,
                showDescription  : true
              }
            },
            {
              path: 'edit/:id',
              name: 'dashboard_module_edit',
              component: AssetEdit,
              props: {
                assetType        : 'module',
                assetTypeToLoad  : false,
                loadPublicAssets : false,
                showDescription  : false,
              }
            }
          ]
        },
        {
          path: 'bilder',
          name: 'dashboard_bilder',
          component: DashboardRight,
          redirect: '/dashboard/bilder/mediathek',
          props: {showQuickMenu: true, quickMenuName: "bilder"},
          children: [
            {
              path: 'mediathek',
              name: 'dashboard_bilder_mediathek',
              component: ImageGrid,
              props: {
                assetType        : 'image',
                assetTypeToLoad  : 'image',
                loadPublicAssets : false ,
                showDescription  : true
              }
            }
          ]
        },
        {
          path: 'videos',
          name: 'dashboard_videos',
          component: DashboardRight,
          redirect: '/dashboard/videos/mediathek',
          props: {showQuickMenu: true, quickMenuName: "videos"},
          children: [
            {
              path: 'mediathek',
              name: 'dashboard_videos_mediathek',
              component: VideoGrid,
              props: {
                loadPublicAssets : false ,
                showDescription  : true
              }
            }
          ]
        },
        {
          path: 'profil',
          name: 'dashboard_profil',
          component: DashboardRight,
          redirect: '/dashboard/profil/details',
          props: {showQuickMenu: true, quickMenuName: "profile"},
          children: [
            {
              path: 'details',
              name: 'dashboard_profil_details',
              component: ProfileDetails
            },
            {
              path: 'player',
              name: 'dashboard_profil_player',
              component: ProfilePlayers
            },
            {
              path:'organization',
              name: 'dashboard_profil_organization',
              component: ProfileOrganization
            }
          ]
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup
    },
    {
      path: '/404',
      component: NOTFOUND
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login']; //'/signup'
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user-token');
  if (authRequired && !loggedIn) {
    return next('/login');
  }
  next();
});

function getChannelData(to, from, next) {

  // GET CHANNEL DAT

  // OPEN CHANNEL VIEW
  next();
}

// NOTES:

/*
The Full Navigation Resolution Flow
   Navigation triggered.
   Call leave guards in deactivated components.
   Call global beforeEach guards.
   Call beforeRouteUpdate guards in reused components.
   Call beforeEnter in route configs.
   Resolve async route components.
   Call beforeRouteEnter in activated components.
   Call global beforeResolve guards.
   Navigation confirmed.
   Call global afterEach hooks.
   DOM updates triggered.
   Call callbacks passed to next in beforeRouteEnter guards with instantiated instances.
*/
