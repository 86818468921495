import { router } from '@/router.js';
import axios      from 'axios';

/* +++++++++++++ */
/* INITIAL STATE */
/* +++++++++++++ */

const token = localStorage.getItem('user-token');
const initialState = token
  ? { status: { loggedIn: true }, token }
  : { status: {}, token: null };

const AUTH_REQUEST  = 'AUTH_REQUEST';
const AUTH_SUCCESS  = 'AUTH_SUCCESS';
const AUTH_ERROR    = 'AUTH_ERROR';
const AUTH_LOGOUT   = 'AUTH_LOGOUT';

/* ++++++++++++++++++++++++++++++++ */
/* AXIOS INTERCEPTOR FOR AUTH TOKEN */
/* ++++++++++++++++++++++++++++++++ */

/* ++++++++++++++++++++++++++++++++ */

export const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    [AUTH_REQUEST] : ({ dispatch, commit }, user ) => {

      commit('AUTH_REQUEST');
      axios.post(process.env.VUE_APP_JUPITER_AUTH + '/login', user)
        .then(r => {
          localStorage.setItem('user-token', r.data.token);
          commit(AUTH_SUCCESS, r.data.token);
          router.push('/');
        })
        .catch(err => {
          dispatch('alert/ALERT_ERROR', err.message, {root:true})
          commit(AUTH_ERROR);
        });

    },
    [AUTH_LOGOUT] : ({ dispatch, commit, state }) => {
      localStorage.removeItem('user-token');
      if (state.status.loggedIn) {
        dispatch('alert/ALERT_SUCCESS', "You've been logged out successfully !", {root:true});
      }
      commit(AUTH_LOGOUT);
    }

  },
  mutations: {
    [AUTH_REQUEST] : (state) => {
      state.status = { loggingIn : true };
    },
    [AUTH_SUCCESS] : (state, token) => {
      state.status = { loggedIn : true };
      state.token  = token;
    },
    [AUTH_ERROR] : (state) => {
      state.status = {};
      state.token  = null;
    },
    [AUTH_LOGOUT] : (state) => {
      state.status = {};
      state.user   = null;
    }
  },
  getters: {
    loggingIn:  (state) => { return state.status.loggingIn ? true : false },
    authStatus: (state) => { return state.status.loggedIn  ? true : false },
    isAuthenticated: (state) => { return !!state.token },
    token: (state) => { return state.token }
  }
}
