
import axios from 'axios';
import _     from 'lodash';

const ASSETS_FETCH    = 'ASSETS_FETCH';
const IMAGES_FETCH    = 'IMAGES_FETCH';
const ASSETS_STORE    = 'ASSETS_STORE';
const IMAGES_STORE    = 'IMAGES_STORE';

const NEW_ASSET       = 'NEW_ASSET';
const GET_ASSET       = 'GET_ASSET';
const UPDATE_ASSET    = 'UPDATE_ASSET';
const REMOVE_ASSET    = 'REMOVE_ASSET';

const NEW_IMAGE       = 'NEW_IMAGE';
const REMOVE_IMAGE    = 'REMOVE_IMAGE';

const PUSH_ASSET_TO_STORE = 'PUSH_ASSET_TO_STORE';
const PUSH_IMAGE_TO_STORE = 'PUSH_IMAGE_TO_STORE';
const REMOVE_ASSET_FROM_STORE = 'REMOVE_ASSET_FROM_STORE';
const REMOVE_IMAGE_FROM_STORE = 'REMOVE_IMAGE_FROM_STORE';

const ASSETS_LOADING  = 'ASSETS_LOADING';
const IMAGES_LOADING  = 'IMAGES_LOADING';

export const assets = {
  namespaced: true,
  state: {
    assets    : [],
    images    : [],
    ///////////////////
    assetsLoading : false,
    imagesLoading : false
  },
  actions: {

    /* ++++++++++++++++++++++++++++++++++++++++++ */
    /* VUEX ACTION TO GET ASSETS FROM DATABASE    */
    /* ++++++++++++++++++++++++++++++++++++++++++ */
    [ASSETS_FETCH] : ({ dispatch, commit, state, rootGetters }) => {

      // GET ASSETS
      commit(ASSETS_LOADING, true);
      axios.get(process.env.VUE_APP_JUPITER_API + '/user/assets?member=true')
      .then(r => {
        commit(ASSETS_STORE, r.data.payload.assets);
      })
      .catch(err => {
        dispatch('alert/ALERT_ERROR', err.message, {root:true});
      })
      .finally(() => {
        commit(ASSETS_LOADING, false);
      });

    },

    /* ++++++++++++++++++++++++++++++++++++++++++ */
    /* VUEX ACTION TO CREATE NEW ASSET            */
    /* ++++++++++++++++++++++++++++++++++++++++++ */

    [NEW_ASSET] : ({ dispatch, commit, state, rootGetters }, type) => {
      return new Promise((resolve, reject) => {

        // CREATE NEW ASSET
        axios.post(process.env.VUE_APP_JUPITER_API + '/user/assets', {type:type})
        .then(r => {
          if (r.data && r.data.payload && r.data.payload[type]) {
            commit(PUSH_ASSET_TO_STORE, r.data.payload[type]);
            dispatch('alert/ALERT_SUCCESS', `${type.toUpperCase()} mit der ID ${r.data.payload[type]._shortid} wurde erstellt.`, {root:true});
            return resolve(r.data.payload[type])
          } else {
            return resolve();
          }
        })
        .catch(err => {
          dispatch('alert/ALERT_ERROR', err.message, {root:true});
          return resolve();
        });

      });
    },

    /* ++++++++++++++++++++++++++++++++++++++++++ */
    /* VUEX ACTION TO GET ONE SPECIFIC ASSET      */
    /* ++++++++++++++++++++++++++++++++++++++++++ */

    [GET_ASSET] : ({ dispatch, commit, state, rootGetters }, id) => {
      return new Promise((resolve, reject) => {

        // GET ONE ASSET BY ID
        axios.get(process.env.VUE_APP_JUPITER_API + '/user/assets/' + id + '?populate=true')
        .then(r => {
          return resolve(r.data.payload);
        })
        .catch(err => {
          dispatch('alert/ALERT_ERROR', err.message, {root:true});
          return reject(err.message);
        });

      });
    },

    /* ++++++++++++++++++++++++++++++++++++++++++ */
    /* VUEX ACTION TO UPDATE ONE SPECIFIC ASSET   */
    /* ++++++++++++++++++++++++++++++++++++++++++ */

    [UPDATE_ASSET] : ({ dispatch, commit, state, rootGetters }, {id, body}) => {
      return new Promise((resolve, reject) => {

        // UPDATE ONE ASSET BY ID WITH BODY
        axios.put(process.env.VUE_APP_JUPITER_API + '/user/assets/' + id, body)
        .then(r => {
          dispatch('assets/ASSETS_FETCH', null, {root:true});
          dispatch('alert/ALERT_SUCCESS', 'Erfolgreich gespeichert !', {root:true});
          return resolve(r.data.payload);
        })
        .catch(err => {
          dispatch('alert/ALERT_ERROR', err.message, {root:true});
          return reject(err.message);
        });

      });
    },

    [REMOVE_ASSET] : ({ dispatch, commit, state, rootGetters}, id) => {
      return new Promise((resolve, reject) => {

        // REMOVE ONE ASSET BY ID
        axios.delete(process.env.VUE_APP_JUPITER_API + '/user/assets/' + id)
        .then( r => {
          commit(REMOVE_ASSET_FROM_STORE, id);
          dispatch('alert/ALERT_SUCCESS', 'Erfolgreich entfernt !', {root:true});
          return resolve();
        })
        .catch( err => {
          dispatch('alert/ALERT_ERROR', err.message, {root:true});
          return resolve();
        });

      });
    },

    /* ++++++++++++++++++++++++++++++++++++++++++ */
    /* VUEX ACTION TO GET IMAGES FROM DATABASE    */
    /* ++++++++++++++++++++++++++++++++++++++++++ */
    [IMAGES_FETCH] : ({ dispatch, commit, state, rootGetters }) => {

      commit(IMAGES_LOADING, true);
      axios.get(process.env.VUE_APP_IMAGE_API + '/files')
      .then(r => {
        commit(IMAGES_STORE, r.data.payload.images);
      })
      .catch(err => {
        dispatch('alert/ALERT_ERROR', err.message, {root:true});
      })
      .finally(() => {
        commit(IMAGES_LOADING, false);
      });

    },

    /* ++++++++++++++++++++++++++++++++++++++++++ */
    /* VUEX ACTION TO GET IMAGES FROM DATABASE    */
    /* ++++++++++++++++++++++++++++++++++++++++++ */
    [REMOVE_IMAGE] : ({ dispatch, commit, state, rootGetters }, id) => {
      return new Promise((resolve, reject) => {

        // REMOVE ONE IMAGE BY ID
        axios.delete(process.env.VUE_APP_IMAGE_API + '/files/' + id)
        .then( r => {
          commit(REMOVE_IMAGE_FROM_STORE, id);
          dispatch('alert/ALERT_SUCCESS', 'Erfolgreich entfernt !', {root:true});
          return resolve();
        })
        .catch( err => {
          dispatch('alert/ALERT_ERROR', err.message, {root:true});
          return resolve();
        });

      });
    }

  },
  mutations: {

    /* ++++++++++++++++++++++++++++++++++++++++++ */
    /* VUEX MUTATION TO SET ASSETS DATA TO STORE  */
    /* ++++++++++++++++++++++++++++++++++++++++++ */
    [ASSETS_STORE] : (state, assets) => {
      state.assets = assets;
    },
    [PUSH_ASSET_TO_STORE] : (state, newasset) => {
      state.assets.push(newasset);
    },
    [REMOVE_ASSET_FROM_STORE] : (state, idOfRemovedAsset) => {
      const index = state.assets.findIndex(asset => asset._id === idOfRemovedAsset);
      state.assets.splice(index, 1);
    },
    [REMOVE_IMAGE_FROM_STORE] : (state, idOfRemovedImage) => {
      const index = state.images.findIndex(image => image._id === idOfRemovedImage);
      state.images.splice(index, 1);
    },

    /* ++++++++++++++++++++++++++++++++++++++++++ */
    /* VUEX MUTATION TO SET IMAGES DATA TO STORE  */
    /* ++++++++++++++++++++++++++++++++++++++++++ */
    [IMAGES_STORE] : (state, images) => {
      state.images = images;
    },

    /* ++++++++++++++++++++++++++++++++++++++++++ */
    /* VUEX MUTATION TO SET USER LOADING STATUS   */
    /* ++++++++++++++++++++++++++++++++++++++++++ */
    [ASSETS_LOADING] : (state, bool) => {
      state.assetsLoading = bool;
    },
    [IMAGES_LOADING] : (state, bool) => {
      state.imagesLoading = bool;
    }

  },
  getters: {
    channels(state, getters, rootState, rootGetters) {
      let userid = rootGetters["user/_id"];
      return state.assets.filter(a => a.type === "channel" && a.user === userid);
    },
    channelsMember(state, getters, rootState, rootGetters) {
      let userid = rootGetters["user/_id"];
      return state.assets.filter(a => a.type === "channel" && a.member.some(m => m === userid));
    },
    pages(state, getters, rootState, rootGetters) {
      let userid = rootGetters["user/_id"];
      return state.assets.filter(a => a.type === "page" && a.user === userid);
    },
    pagesMember(state, getters, rootState, rootGetters) {
      let userid = rootGetters["user/_id"];
      return state.assets.filter(a => a.type === "page" && a.member.some(m => m === userid));
    },
    "mobile-pages" : (state, getters, rootState, rootGetters) => {
      let userid = rootGetters["user/_id"];
      return state.assets.filter(a => a.type === "mobile-page" && a.user === userid);
    },
    "mobile-pagesMember" : (state, getters, rootState, rootGetters) => {
      let userid = rootGetters["user/_id"];
      return state.assets.filter(a => a.type === "mobile-page" && a.member.some(m => m === userid));
    },
    modules(state, getters, rootState, rootGetters) {
      let userid = rootGetters["user/_id"];
      return state.assets.filter(a => a.type === "module" && a.user === userid);
    },
    modulesMember(state, getters, rootState, rootGetters) {
      let userid = rootGetters["user/_id"];
      return state.assets.filter(a => a.type === "module" && a.member.some(m => m === userid));
    },
    images(state) {
      return state.images;
    },
    publicChannels(state) {
      return [];
    },
    publicPages(state) {
      return [];
    },
    publicModules(state) {
      return [];
    }
  }
}
