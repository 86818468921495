<template lang="pug">
div
  .field
    label.label {{ label }}

    // BUTTON TO OPEN MODAL AND SET URL
    a.button.is-light(
      v-if="!value"
      @click="openModal"
      )
      span.icon
        i.fas.fa-plus
      span Aus deiner Mediathek auswählen

    // BOX TO SHOW IMAGE IF URL IS SET
    template(v-else)
      .box
        figure.image.is-16by9.selected-image
          img(:src="value" @click="openModal" style="object-fit:contain")
      a.button.is-light(
        @click="setImage('')"
        )
        span.icon
          i.fas.fa-minus-circle
        span Bild entfernen

  // MODAL TO CHOOSE IMAGE
  .modal(:class="[modalActive ? 'is-active' : '']")
    .modal-background
    .modal-card
      header.modal-card-head
        p.modal-card-title Mediathek
        a.button.delete.is-large.is-primary(@click="closeModal")
      section.modal-card-body

        // MEDIATHEK IMAGE GRID OVERVIEW
        .columns.is-multiline
          .column.is-3(
            v-for="(image, index) in availableImages"
            :key="index"
            )
            figure.image.is-1by1.grid-card
              img(:src="imageserver + '/f/' + image.filename" @click="setImage(imageserver + '/f/' + image.filename)" style="object-fit:contain")
</template>

<script>

  //import {getAssets}   from '@/_services/assets.service';

  export default {
    props: ['value', 'label'],
    data: function () {
      return {
        modalActive: false
      }
    },
    computed: {
      availableImages() { return this.$store.state.assets.images },
      imageserver() { return process.env.VUE_APP_IMAGE_API }
    },
    methods: {
      closeModal: function() {
        this.modalActive = false;
      },
      openModal: function() {
        this.modalActive = true;
      },
      setImage: function(url) {
        this.closeModal();
        this.$emit('input', url)
      }
    },
    mounted: function () {

    }
  }

</script>

<style scoped>

  .grid-card {
    border-radius: 5px;
    background:rgba(0,0,0,0.05);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  .selected-image {
    border-radius: 5px;
    background:rgba(0,0,0,0.05);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  .selected-image img, .grid-card img {
    object-fit:cover;
    cursor: pointer;
  }

  .selected-image img:hover, .grid-card img:hover {
    opacity: 0.9
  }
</style>
