<template lang="pug">
div
  .container
    .lead
      h1.title Qué?
      h2.subtitle
        | Sorry, I couldn't find what
        br
        | you were looking for.
      router-link.button.is-link(to="/") Back to Dashboard
</template>

<script>
  export default {
    name: 'NOTFOUND',
    data: function() {
      return {

      }
    }
  }
</script>

<style scoped>

  .container {
    background:yellow;
  }

</style>
