<template>
  <div id="app">
    <router-view />
    <alert-bottom />
  </div>

</template>

<script>

  import AlertBottom from '@/components/AlertBottom';

  export default {
    components: {
      AlertBottom
    }
  }

</script>



<style>

  /* html, body {
    min-height: 100vh;
  } */

  body {
    background-color:#DBDBDB;
  }

</style>
