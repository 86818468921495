const ALERT_CLEAR       = 'ALERT_CLEAR';
const ALERT_SUCCESS     = 'ALERT_SUCCESS';
const ALERT_ERROR       = 'ALERT_ERROR';
const ALERT_TIMER       = 'ALERT_TIMER';
const ALERT_PROGRESS    = 'ALERT_PROGRESS';

export const alert = {
  namespaced: true,
  state: {
    type: '',
    message: '',
    timer: null,
    progress: 0
  },
  actions: {
    [ALERT_SUCCESS] : ({ commit, dispatch, state }, message) => {
      commit(ALERT_CLEAR);
      commit(ALERT_SUCCESS, message);
      dispatch(ALERT_TIMER);
    },
    [ALERT_ERROR] : ({ commit, dispatch, state }, message) => {
      commit(ALERT_CLEAR);
      commit(ALERT_ERROR, message);
      dispatch(ALERT_TIMER);
    },
    [ALERT_CLEAR] : ({ commit, state }) => {
      commit(ALERT_CLEAR);
    },
    [ALERT_TIMER] : ({ commit, dispatch }) => {
      let interval = setInterval(() => {
          dispatch(ALERT_CLEAR);
      },7000);
      commit(ALERT_TIMER, interval);
    }
  },
  mutations: {
    [ALERT_SUCCESS] : (state, message) => {
      state.type = 'success';
      state.message = message;
    },
    [ALERT_ERROR] : (state, message) => {
      state.type = 'error';
      state.message = message;
    },
    [ALERT_CLEAR] : (state) => {
      if (state.timer) { clearInterval(state.timer); state.timer = null; }
      state.type = null;
      state.message = null;
    },
    [ALERT_TIMER] : (state, timer) => {
      state.timer = timer;
    },
    [ALERT_PROGRESS] : (state, value) => {
      state.progress = value;
    }
  },
  getters: {
    type(state) { return state.type },
    message(state) { return state.message },
    progress(state) { return state.progress }
  }
}
