<template>
<div>

  <!-- LAYOUT GRID PREVIEW -->
  <div
    class="layout-grid-container"
    v-bind:style="{ display : 'grid', gridTemplateColumns : layouts[layout].columns, gridTemplateRows : layouts[layout].rows, gridTemplateAreas : layouts[layout].template }" >
    <div
      class="layout-grid-holder"
      v-for="(area, index) in layouts[layout].areas"
      :class="area"
      :style="{ gridArea : area }" >

      <div class="field has-addons" style="width:100%">
        <p class="control">
          <a class="button" :class="[ value[index] ? 'is-success' : 'is-danger']">
            <span>{{ index + 1 }}</span>
          </a>
        </p>
        <p class="control">
          <a class="button" @click="removeFromIndex(index)">
            <span class="icon is-small"><i class="fas fa-times"></i></span>
          </a>
        </p>
        <p class="control">
          <a class="button" @click="chooseForIndex(index)">
            <span class="icon is-small"><i class="fas fa-list"></i></span>
          </a>
        </p>
        <!-- POSITION STYLE EDITOR -->
        <form-position-style-editor v-model="positionStyles[index]"/>
      </div>

      <template v-if="value[index]">
        <div class="box" style="padding:10px;" :style="{'borderLeft' : `3px solid ${value[index].meta.labelColor}`}">
          <div class="columns">
            <div class="column">
              <p class="help">
                <strong>Name: </strong>{{ value[index].meta.name }}<br>
                <strong>Typ: </strong>{{ value[index].resource.module }}
              </p>
            </div>
            <div class="column is-narrow">
              <a class="button is-link" :href="`/dashboard/module/edit/${value[index]._id}`" target="_blank">
                <span class="icon"><i class="fas fa-edit"></i></span>
              </a>
            </div>
          </div>
        </div>
      </template>

    </div>
  </div>

  <!-- MODAL TO CHOOSE COMPONENT -->
  <div class="modal" :class="[modalIsActive ? 'is-active' : '']">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Modul wählen</p>
        <button class="delete" @click="closeModal" />
      </header>
      <section class="modal-card-body">

        <!-- MODULES THE USER OWN  -->
        <p class="title is-5">Deine Module</p>
        <p class="subtitle is-7">Module, die du selbst erstellt hast.</p>
        <table class="table is-fullwidth is-narrow is-hoverable is-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Typ</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(module, index) in modules" @click="update(module)">
              <th>{{ index + 1 }}</th>
              <td><code>{{ module.resource.module }}</code></td>
              <td>{{ module.meta.name }}</td>
            </tr>
          </tbody>
        </table>

        <hr>
        <!-- MODULES THE USER IS MEMBER -->
        <p class="title is-5">Mitglieds-Module</p>
        <p class="subtitle is-7">Module, welchen du als Mitglied hinzugefügt wurdest.</p>
        <table class="table is-fullwidth is-narrow is-hoverable is-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Typ</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(module, index) in modulesMember" @click="update(module)">
              <th>{{ index + 1 }}</th>
              <td><code>{{ module.resource.module }}</code></td>
              <td>{{ module.meta.name }}</td>
            </tr>
          </tbody>
        </table>

      </section>
      <footer class="modal-card-foot"></footer>
    </div>
  </div>

</div>
</template>

<script>

  import layouts from '@/_helpers/layouts.helper'
  import FormPositionStyleEditor from '@/components/FormPositionStyleEditor'

  export default {
    props: ['value','layout','modules', 'modulesMember', 'positionStyles'],
    data() {
      return {
        layouts: layouts,
        chosenIndex: 0,
        chosenModules: this.value,
        modalIsActive: false,
        modalPositionStyleIsActive: false
      }
    },
    components: {
      FormPositionStyleEditor
    },
    methods: {
      update(module) {
        this.closeModal();
        let array = [...this.chosenModules];
        array[this.chosenIndex] = module;
        this.chosenModules = array;
        //this.value.$set(this.chosenIndex, module);
        this.$emit('input', this.chosenModules);
      },
      removeFromIndex(index) {
        let array = [...this.chosenModules];
        array[index] = null;
        this.chosenModules = array;
       // this.value.$set(index, null);
        this.$emit('input', this.chosenModules);
      },
      openModal() {
        this.modalIsActive = true;
      },
      closeModal() {
        this.modalIsActive = false;
      },
      chooseForIndex(index) {
        this.chosenIndex = index;
        this.openModal();
      }

    }
    // ,
    // watch: {
    //   // value(newvalue) {
    //   //   this.chosenModules = newvalue;
    //   // }
    // }
  }
</script>

<style scoped>


  tr { cursor:  pointer }

  .layout-grid-container .layout-grid-holder {
    margin:2px;
    padding:5px;
    background:rgba(0,0,0,0.1);
    border-radius:5px;
    height:auto;
  }

  .layout-grid-holder:hover {
    background:rgba(0,0,0,0.2);
  }

  .layout-grid-container {
    margin-top:20px;
    padding: 3px;
    border: 2px solid black;
    border-radius: 5px;
    min-height:300px;
  }

</style>
