<template lang="pug">
.field
  .field
    label.label.has-text-light {{ label }} ( {{tags.length}} / 8 )
    .control
      input.input(type="text" v-model="input" @keydown.enter="addInputToTags" v-if="tags.length < 8")
  .field.is-grouped.is-grouped-multiline
    .control(v-for="(tag, index) in tags" :key="index")
      .tags.has-addons
        .tag.is-light {{ tag }}
        a.tag.is-delete.is-light( @click="removeTag(index)" )
</template>

<script>

  export default {
    props:['tags'],
    data: function () {
      return {
        label: "Tags",
        input: ""
      }
    },
    methods: {
      addInputToTags: function() {
        if (this.tags.length >= 8) { return };
        let input = this.input.split(" ");
        if (input[0].length < 4)  { return }
        if (input[0].length > 12) { this.clearInput(); return }
        this.tags.push(input[0]);
        this.clearInput();
      },
      removeTag: function (index) {
        this.tags.splice(index, 1);
      },
      clearInput: function() {
        this.input = "";
      }
    }
  }

</script>

<style>
</style>
