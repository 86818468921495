<template>
  <div>

    <div class="section" style="display:flex; flex-direction:column; align-items:center;">
      <h1 class="title">Diese Funktion ist bald verfügbar.</h1>
      <div class="figure">
        <img class="" src="/assets/women_grass_tree.png">
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        
      }
    }
  }
</script>

<style scoped>
</style>
