<template lang="pug">
div

  // BUTTON
  a.button.is-dark(@click="openModal")
    span.icon
      i.fas.fa-plus
    span Layout ändern

  // LAYOUT MODAL
  .modal(:class="{'is-active' : modalIsActive}")
    .modal-background
    .modal-card
      header.modal-card-head
        p.modal-card-title Layout
        a.button.delete.is-primary.is-large(@click="closeModal")
      section.modal-card-body

        // LIST OF AVAILABLE LAYOUTS
        .columns.is-multiline
          .column.is-one-quarter(v-for="(layout,key,index) in layouts")
            .layout-grid-container-small(
              v-bind:style="{ display : 'grid', gridTemplateColumns : layout.columns, gridTemplateRows : layout.rows, gridTemplateAreas : layout.template }"
              )
              div(
                v-for="area in layout.areas"
                :class="area"
                :style="{ gridArea : area }"
                @click="update(key)"
                )

      footer.modal-card-foot
</template>

<script>

  import layouts from '@/_helpers/layouts.helper'

  export default {
    props: ['value'],
    data() {
      return {
        modalIsActive: false,
        layouts: layouts
      }
    },
    methods: {
      openModal() {
        this.modalIsActive = true;
      },
      closeModal() {
        this.modalIsActive = false;
      },
      update(layout) {
        this.closeModal();
        this.$emit('input', layout)
      }
    }

  }
</script>

<style scoped>

  .layout-grid-container-small {
    padding:3px;
    border:2px solid black;
    border-radius:5px;
    min-height:110px;
    cursor:pointer;
  }

  .layout-grid-container-small div {
    margin:2px;
    border:1px solid black;
    border-radius:3px;
    height:auto;
  }

  .layout-grid-container-small:hover {
    border-color:#444444;
  }

  .layout-grid-container-small:hover div {
    background:#444444;
    border-color:#444444;
  }

</style>
