<template>
  <div>
    <template v-if="!isLoading">

      <!-- IF USER HAS organizations -->
      <template v-if="userOrganizations.length > 0">
        <div class="box" v-for="organization in userOrganizations" :style="{borderLeft : `3px solid ${organization.meta.labelColor}`}">
          <div class="columns">

            <!-- META INFO OF organization -->
            <div class="column is-narrow">
              <p class="title is-4">
                <span
                  class="tag is-normal"
                  :class="[organization.meta.listed ? 'is-primary' : 'is-danger']"
                  style="margin-right:10px;">{{organization.meta.listed ? 'Aktiv' : 'Inaktiv'}}</span>
                <span class="organization-title">{{organization.meta.name}}</span>
              </p>
              <p class="subtitle is-7">{{organization.meta.description}}</p>
              <p class="help">{{ organization._shortid }} - {{ organization._id }}</p>
              <a class="button is-fullwidth is-outlined is-dark" style="margin-top:20px;" @click="saveOrganization(organization)"><strong>Speichern</strong></a>
            </div>

            <!-- ALL PAGES OF organization -->
            <div class="column">
              <form-channel-editor
                v-model="organization.pages"
                :pages="availablePages"
                :pagesMember="[]"
                :pageDurations="organization.pageDurations"
                :timeDisplay="organization.timeDisplay"
                @durations="(durations) => { organization.pageDurations = durations; }" />
                
            </div>

          </div>
        </div>
      </template>

      <!-- IF NO organizations ARE AVAILABLE -->
      <template v-else>
        <div class="section" style="display:flex; flex-direction:column; align-items:center;">
          <h1 class="title">Diese Funktion ist bald verfügbar.</h1>
          <div class="figure">
            <img class="" src="/assets/women_grass_tree.png">
          </div>
        </div>
      </template>

    </template>
    <loading-indicator v-else />
  </div>
</template>

<script>

  import LoadingIndicator from '@/components/LoadingIndicator'
  import FormChannelEditor from '@/components/FormChannelEditor'

  export default {
    data() {
      return {
        isSaving : false
      }
    },
    computed: {
      isLoading() {
        return this.$store.state.organization.isLoading;
      },
      userOrganizations() {
        return this.$store.state.organization.available;
      },
      availablePages() {
        return this.$store.getters["assets/pages"]
      }
    },
    components: {
      LoadingIndicator,
      FormChannelEditor
    },
    methods: {
      onDurations(durations) {
        console.log(durations)
      },
      saveOrganization(org) {
        this.isSaving = true;

        org.pageDurations = org.pages.map((page,index) => {
          let d = org.pageDurations[index];
          if (!d)       { d = 15;  }
          if (isNaN(d)) { d = 15;  }
          if (d > 100000)  { d = 100000; }
          if (d < 5)    { d = 5;  }
          return d;
        });

        const body = {
          meta           : org.meta,
          pages          : org.pages,
          pageDurations  : org.pageDurations,
          timeDisplay    : org.timeDisplay
        };

        const id = org._id;
        this.$store.dispatch('organization/SAVE', {id, body})
        .then((payload) => {

        })
        .catch((err) => {

        })
        .finally(() => {
          this.isSaving = false;
        });

      }
    },
    mounted() {

    }
  }
</script>

<style scoped>

  .organization-title {
    cursor: pointer;
  }

  .organization-title:hover{
    background:rgba(0,0,0,0.2);
  }


</style>
