<template>
  <div>

    <!-- BUTTON -->
    <a class="button is-dark is-outlined"
      @click="openModal"
      style="margin-bottom:20px"
      :disabled="chosenPages.length > 9">
      <span class="icon"><i class="fas fa-plus"></i></span>
      <strong>Modul hinzufügen ({{ chosenPages.length }})</strong>
    </a>


    <draggable v-model="chosenPages" :options="{handle:'.draghandle'}" @change="updateOrder">
      <div
        class="box"
        v-for="(page, index) in chosenPages">
        <div class="columns is-vcentered has-background-light">

          <!-- DRAG HANDLE -->
          <div class="column is-narrow">
            <a class="button draghandle is-primary">
              <span class="icon"><i class="fas fa-grip-lines"></i></span>
            </a>
          </div>

          <div class="column is-narrow">
            <a class="button is-link" :href="`/dashboard/module/edit/${page._id}`" target="_blank">
              <span class="icon"><i class="fas fa-edit"></i></span>
            </a>
          </div>

          <!-- PAGE POSITION IN CHANNEL -->
          <div class="column is-narrow">
            <strong>{{ index + 1 }}</strong>
          </div>

          <!-- NAME & DESCRIPTION -->
          <div class="column">
            <p><strong>{{ page.meta.name }}</strong></p>
            <p>{{ page.meta.description }}</p>
            <b-field grouped>
              <b-field label="Externer Link" label-position="inside">
                <b-input placeholder="Hyperlink"
                  v-model="pageLinks[index]"
                  type="text">
                </b-input>
              </b-field>
              <b-field label="Höhe überschreiben" label-position="inside">
                <b-input
                  v-model.number="sectionHeights[index]"
                  type="number"
                  min="0"
                  max="9999"
                  placeholder="150">
                </b-input>
              </b-field>
            </b-field>
          </div>

          <!-- PAGE DURATION -->
          <div class="column is-narrow" >
            <div class="field">
              <!-- <label class="help label">Anzeige (Sek.)</label> -->
              <!-- <div class="control" >
                <input class="input" type="number" v-model.number="pageDurations[index]" style="max-width:70px">
              </div> -->
            </div>
          </div>

          <!-- PAGE REMOVE -->
          <div class="column is-narrow">
            <a class="button is-danger" @click="remove(index)">
              <span class="icon is-small"><i class="fas fa-times"></i></span>
            </a>
          </div>
        </div>
      </div>
    </draggable>

    <!-- PAGE MODAL -->
    <div class="modal" :class="{'is-active':modalIsActive}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Seite wählen</p>
          <button class="delete" @click="closeModal"></button>
        </header>
        <section class="modal-card-body">

          <!-- PAGES THE USER OWN -->
          <p class="title is-5">Deine Module</p>
          <p class="subtitle is-7">Module, die du selbst erstellt hast.</p>
          <table class="table is-fullwidth is-narrow is-hoverable is-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Typ</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(page, index) in pages" @click="update(page)">
                <th>{{ index + 1 }}</th>
                <td><code>{{ page.resource.module }}</code></td>
                <td>{{ page.meta.name }}</td>
              </tr>
            </tbody>
          </table>

          <hr>
          <!-- PAGES THE USER IS MEMBER -->
          <p class="title is-5">Mitglieds-Module</p>
          <p class="subtitle is-7">Module, welchen du als Mitglied hinzugefügt wurdest.</p>
          <table class="table is-fullwidth is-narrow is-hoverable is-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Typ</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(page, index) in pagesMember" @click="update(page)">
                <th>{{ index + 1 }}</th>
                <td><code>{{ page.resource.module }}</code></td>
                <td>{{ page.meta.name }}</td>
              </tr>
            </tbody>
          </table>

        </section>
        <footer><div class="modal-card-foot"></div></footer>
      </div>
    </div>

  </div>

</template>

<script>

  import draggable from 'vuedraggable'

  export default {
    props: ['value', 'pages', 'pagesMember', 'pageLinks', 'sectionHeights'],
    data() {
      return {
        chosenPages: this.value,
        modalIsActive: false
      }
    },
    components: {
      draggable
    },
    methods: {
      move(arr, oldIndex, newIndex) {
        if (newIndex >= arr.length) {
          let k = newIndex - arr.length + 1;
          while (k--) { arr.push(undefined); }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr;
      },
      updateOrder(e) {
        let pageLinks = this.move([...this.pageLinks], e.moved.oldIndex, e.moved.newIndex);
        this.$emit('links', pageLinks)

        let sectionHeights = this.move([...this.sectionHeights], e.moved.oldIndex, e.moved.newIndex);
        this.$emit('heights', sectionHeights)

        let array = [...this.chosenPages];
        this.$emit('input', array);
      },
      update(page) {
        this.closeModal();
        let array = [...this.chosenPages];
        array.push(page);
        this.chosenPages = array;
        this.$emit('input', array);
      },
      remove(index) {
        let array = [...this.chosenPages];
        array.splice(index,1);
        this.chosenPages = array;
        this.$emit('input', array);

        let pageLinks = [...this.pageLinks];
        pageLinks.splice(index,1);
        this.$emit('links', pageLinks);

        let sectionHeights = [...this.sectionHeights];
        sectionHeights.splice(index,1);
        this.$emit('heights', sectionHeights);
      },
      openModal() {
        this.modalIsActive = true;
      },
      closeModal() {
        this.modalIsActive = false;
      }
    }
    // watch : {
    //   value(newvalue) {
    //     this.chosenPages = newvalue;
    //   }
    // }
  }
</script>

<style scoped>

  tr { cursor:  pointer }

</style>
