<template>
  <div class="form-position-style-editor control">
    <div class="dropdown is-hoverable">
      <div class="dropdown-trigger">
        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu4">
          <span class="icon is-small">
            <i class="fas fa-cog"></i>
          </span>
        </button>
      </div>
      <div class="dropdown-menu" id="dropdown-menu4" role="menu">
        <div class="dropdown-content">
          <div class="dropdown-item">
            <p style="margin-bottom:5px"><strong>Abgerundete Ecken (px)</strong></p>
            <div class="columns">
              <div class="column">
                <input class="input is-small" min="0" type="number" v-model="borderTopLeftRadius" style="margin-bottom:2px" v-on:change="update">
                <input class="input is-small" min="0" type="number" v-model="borderBottomLeftRadius" v-on:change="update">
              </div>
              <div class="column">
                <input class="input is-small" min="0" type="number" v-model="borderTopRightRadius" style="margin-bottom:2px" v-on:change="update">
                <input class="input is-small" min="0" type="number" v-model="borderBottomRightRadius" v-on:change="update">
              </div>
            </div>
          </div>
          <div class="dropdown-item">
            <p style="margin-bottom:5px"><strong>Schatten</strong></p>
            <div class="field is-horizontal">
              <div class="field-label is-small" >
                <label class="label">X Offset (px)</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input is-small" type="number" v-model="boxShadowOffsetX" style="margin-bottom:2px" v-on:change="update">
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-small" >
                <label class="label">Y Offset (px)</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input is-small" type="number" v-model="boxShadowOffsetY" style="margin-bottom:2px" v-on:change="update">
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-small" >
                <label class="label">Unscharf</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input is-small" type="number" v-model="boxShadowBlurRadius" style="margin-bottom:2px" v-on:change="update">
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-small" >
                <label class="label">Größe</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input is-small" type="number" v-model="boxShadowSpreadRadius" style="margin-bottom:2px" v-on:change="update">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    props: ['value'],
    data() {
      return {
        borderTopLeftRadius     : this.value && this.value.borderTopLeftRadius      ? this.value.borderTopLeftRadius     : 0,
        borderTopRightRadius    : this.value && this.value.borderTopRightRadius     ? this.value.borderTopRightRadius    : 0,
        borderBottomLeftRadius  : this.value && this.value.borderBottomLeftRadius   ? this.value.borderBottomLeftRadius  : 0,
        borderBottomRightRadius : this.value && this.value.borderBottomRightRadius  ? this.value.borderBottomRightRadius : 0,
        boxShadowOffsetX        : this.value && this.value.boxShadowOffsetX         ? this.value.boxShadowOffsetX        : 0,
        boxShadowOffsetY        : this.value && this.value.boxShadowOffsetY         ? this.value.boxShadowOffsetY        : 0,
        boxShadowBlurRadius     : this.value && this.value.boxShadowBlurRadius      ? this.value.boxShadowBlurRadius     : 0,
        boxShadowSpreadRadius   : this.value && this.value.boxShadowSpreadRadius    ? this.value.boxShadowSpreadRadius   : 0,
        boxShadowColorHex       : this.value && this.value.boxShadowColorHex        ? this.value.boxShadowColorHex       : '#000000',
        boxShadowInvert         : this.value && this.value.boxShadowInvert          ? this.value.boxShadowInvert         : false
      }
    },
    methods: {
      update() {
        this.$emit('input', {
          borderTopLeftRadius     : this.borderTopLeftRadius,
          borderTopRightRadius    : this.borderTopRightRadius,
          borderBottomLeftRadius  : this.borderBottomLeftRadius,
          borderBottomRightRadius : this.borderBottomRightRadius,
          boxShadowOffsetX        : this.boxShadowOffsetX,
          boxShadowOffsetY        : this.boxShadowOffsetY,
          boxShadowBlurRadius     : this.boxShadowBlurRadius,
          boxShadowSpreadRadius   : this.boxShadowSpreadRadius,
          boxShadowColorHex       : '#000000',
          boxShadowInvert         : false
        });
      }
    },
    mounted() {

    }
  }
</script>

<style lang="css" scoped>

  .field-label {
    min-width:70px;
  }
  .field-label .label {
    color:rgba(0,0,0,0.5);
  }

  .dropdown-content .field {
    margin-bottom:0px;
  }

</style>

<!-- const PositionStyleSchema = Schema({
  borderTopLeftRadius     : { type: Number,  'default': 0 },
  borderTopRightRadius    : { type: Number,  'default': 0 },
  borderBottomLeftRadius  : { type: Number,  'default': 0 },
  borderBottomRightRadius : { type: Number,  'default': 0 },
  boxShadowOffsetX        : { type: Number,  'default': 0 },
  boxShadowOffsetY        : { type: Number,  'default': 0 },
  boxShadowBlurRadius     : { type: Number,  'default': 0 },
  boxShadowSpreadRadius   : { type: Number,  'default': 0 },
  boxShadowColorHex       : { type: String,  'default': '#000000' },
  boxShadowInvert         : { type: Boolean, 'default': false }
}); -->
