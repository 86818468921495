<template>
  <div class="columns">

    <!-- /////////////////////////////////////////////////////////////////// -->

    <div class="column">

      <h2 class="subtitle">Aktuelles Wetter & 3 Tages-Vorschau</h2>

      <!-- ZIP CODE -->
      <div class="field is-horizontal">
        <label class="label field-label">Ortsname</label>
        <div class="control field-body">
          <input class="input" type="text" v-model="name" v-on:change="update" />
        </div>
      </div>
      <!--           -->

      <!-- ZIP CODE -->
      <div class="field is-horizontal">
        <label class="label field-label">Postleitzahl</label>
        <div class="control field-body">
          <input class="input" type="text" v-model="zip" v-on:change="update" />
        </div>
      </div>
      <!--           -->

      <!-- SHOW TIME -->
      <div class="field is-horizontal">
        <label class="label field-label">Land</label>
        <div class="control field-body">
          <div class="select">
            <select v-model="country" v-on:change="update">
              <option value="at">Österreich</option>
              <option value="de">Deutschland</option>
            </select>
          </div>
        </div>
      </div>
      <!--           -->

      <!-- SHOW TIME -->
      <div class="field is-horizontal">
        <label class="label field-label">Sprache</label>
        <div class="control field-body">
          <div class="select">
            <select v-model="language" v-on:change="update">
              <option value="de">Deutsch</option>
              <option value="en">English</option>
            </select>
          </div>
        </div>
      </div>
      <!--           -->

    </div>
    <div class="column" style="background:rgba(0,0,0,0.2)">
    </div>

    <!-- /////////////////////////////////////////////////////////////////// -->

  </div>
</template>

<script>

  export default {
    props: ['value'],
    data() {
      return {
        name     : this.value.name     != null ? this.value.name : "Hallein",
        zip      : this.value.zip      != null  ? this.value.zip : 5400,
        country  : this.value.country  != null ? this.value.country : "at",
        language : this.value.language != null ? this.value.language : "de"
      }
    },
    methods: {
      update() {
        this.$emit('input', {
          name    : this.name,
          zip     : this.zip,
          country : this.country,
          language: this.language
        });
      }
    },
    mounted() {
    }
  }

</script>

<style scoped>
</style>
