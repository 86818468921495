<template lang="html">
  <div class="card has-background-dark form-asset-members" style="margin-bottom:10px; ">
    <header class="card-header">
      <div class="card-header-title has-text-light">
        <i class="fas fa-info-circle" style="margin-right:5px"></i>
        <span>Mitglieder</span>
      </div>
    </header>
    <div class="card-content">
      <div class="content">
        <p class="help has-text-light" v-if="isOwner">Klicke auf das rote X um ein Mitglied zu entfernen.</p>
        <div class="field is-grouped is-grouped-multiline">
          <div class="control" v-for="(m, index) in member">
            <div class="tags has-addons">
              <div class="tag">
                @{{ m.username }}
              </div>
              <div class="tag is-delete" @click="removeMember(m._id, index)" v-if="isOwner"></div>
            </div>
          </div>
        </div>
      </div>
      <hr v-if="isOwner">
      <div class="content" v-if="isOwner">
        <p class="help has-text-light">Mitglied hinzufügen : Username eingeben.</p>
        <div class="field has-addons">
          <div class="control">
            <input class="input is-small has-background-dark has-text-light" type="text" placeholder="Username" v-model="newMember">
          </div>
          <div class="control">
            <a class="button is-black is-small" @click="addMember" :class="{'is-loading' : isLoading }">
              Hinzufügen
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    props: ['member', 'asset'],
    data() {
      return {
        isLoading : false,
        newMember : ''
      }
    },
    computed: {
      userid() { return this.$store.getters["user/_id"] },
      isOwner() { return this.userid == this.asset.user }
    },
    methods: {
      removeMember(id, index) {
        this.$axios.delete(process.env.VUE_APP_JUPITER_API + "/user/assets/" + this.asset._id + "/member", {data : {id : id}})
        .then(res => {
          if (res.data && res.data.code == 200 && res.data.status == "ok") {
            this.member.splice(index, 1);
            this.$store.dispatch('alert/ALERT_SUCCESS', "Der User wurde als Mitglied entfernt");
          }
        })
        .catch(err => {
          this.$store.dispatch('alert/ALERT_ERROR', err.message);
        });
      },
      addMember() {
        this.isLoading = true;
        this.$axios.put(process.env.VUE_APP_JUPITER_API + "/user/assets/" + this.asset._id + "/member", {username: this.newMember})
        .then(res => {
          if (res.data && res.data.code == 200 && res.data.status == "ok") {
            this.newMember = "";
            this.$store.dispatch('alert/ALERT_SUCCESS', "Der User wurde als Mitglied hinzugefügt");
          }
        })
        .catch(err => {
          this.$store.dispatch('alert/ALERT_ERROR', err.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
      }
    }
  }

</script>

<style lang="css" scoped>

  .form-asset-members {
    margin-top:10px;
  }

</style>

<!--   .field.is-grouped.is-grouped-multiline
          .control(v-for="(tag, index) in tags" :key="index")
            .tags.has-addons
              .tag.is-light {{ tag }}
              a.tag.is-delete.is-light( @click="removeTag(index)" ) -->
