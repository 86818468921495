<template>
  <div>

    <!-- BUTTON -->
    <div class="field">
      <label class="label">Bilder hinzufügen</label>
      <a class="button is-light" @click="openModal">
        <span class="icon">
          <i class="fas fa-plus"></i>
        </span>
        <span>Aus deiner Mediathek hinzufügen</span>
      </a>
    </div>

    <!-- HELP / HINT -->
    <p class="help">
      <span class="icon"><i class="fas fa-calendar-day"></i></span>
      Mit dem Datum gibst Du an, bis wann das Bild in der Galerie angezeigt werden soll.</br>Denke daran, alte Bilder regelmäßig rauszunehmen um die Übersicht nicht zu verlieren!
    </p>

    <!-- IMAGE ENTRIES -->
    <div style="margin-top:30px;">
      <draggable v-model="urls" :options="{handle:'.draghandle'}" @change="updateOrder">
        <div class="box image-list-box" v-for="(image, index) in urls" :key="'list-' + index">
          <div class="columns is-vcentered">
            <div class="column is-narrow is-paddingless draghandle"><span class="icon"><i class="fas fa-grip-lines"></i></span></div>
            <div class="column is-narrow">

              <!-- CHOOSE DATE -->
              <div class="field has-addons">
                <p class="control">
                  <a class="button is-primary is-small">
                    <span class="icon"><i class="fas fa-calendar-day"></i></span>
                  </a>
                </p>
                <p class="control">
                  <input class="input is-small" type="date" placeholder="" v-model="dates[index]">
                </p>
              </div>

            </div>
            <div class="column is-paddingless dropdown is-right is-hoverable dropdown-trigger"
                 :style="{
                    background : `url(${image})`,
                    backgroundSize : 'cover',
                    backgroundPosition : 'center',
                    marginLeft : '20px',
                    marginRight : '20px',
                    borderRadius : '5px',
                    minHeight : '70px',
                 }">
                 <div class="dropdown-menu" style="max-width:100px;">
                   <div class="dropdown-content">
                     <div class="dropdown-item">
                       <img :src="image" />
                     </div>
                   </div>
                 </div>
            </div>
            <div class="column is-narrow is-paddingless" @click="removeImage(index)"><span class="icon"><i class="fas fa-times"></i></span></div>
          </div>
          <div class="columns is-vcentered">
            <div class="column is-narrow is-paddingless"><span class="icon"><i class=""></i></span></div>
            <div class="column">

              <div class="field is-horizontal">
                <div class="field-label is-small">
                  <label class="label">URL</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text" placeholder="https://link.to.some.url.com" v-model="links[index]">
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </draggable>
    </div>

    <!-- MEDIATHEK MODAL -->
    <div class="modal" :class="[modalActive ? 'is-active' : '']">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Mediathek</p>
          <a class="button delete is-large is-primary" @click="closeModal"></a>
        </header>
        <section class="modal-card-body">
          <div class="columns is-multiline">
            <div class="column is-3" v-for="(image, index) in availableImages" :key="'modal-' + index">
              <div class="figure image is-1by1 grid-card">
                <img :src="imageServer + '/f/' + image.filename" @click="addImage(imageServer + '/f/' + image.filename)" style="object-fit:contain">
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

  </div>
</template>

<script>

  //import {getAssets}   from '@/_services/assets.service';
  import draggable from 'vuedraggable'

  export default {
    props: ['value', 'dates', 'links'],
    data: function () {
      return {
        urls : this.value ? this.value : [],
        modalActive: false
      }
    },
    components : {
      draggable
    },
    computed: {
      availableImages() { return this.$store.state.assets.images },
      imageServer() {
        return process.env.VUE_APP_IMAGE_API
      }
    },
    methods: {
      move(arr, oldIndex, newIndex) {
        if (newIndex >= arr.length) {
          let k = newIndex - arr.length + 1;
          while (k--) { arr.push(undefined); }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr;
      },
      closeModal: function() {
        this.modalActive = false;
      },
      openModal: function() {
        this.modalActive = true;
      },
      addImage : function (url) {
        let urls = [...this.urls];
        urls.push(url);
        this.urls = urls;
        this.update()
      },
      removeImage : function (index) {
        let urls = [...this.urls];
        urls.splice(index, 1);
        this.urls = urls;
        this.update();

        let dates = [...this.dates];
        dates.splice(index, 1);
        this.$emit('dates', dates);

        let links = [...this.links];
        links.splice(index, 1);
        this.$emit('links', links);
      },
      updateOrder : function (e) {

        let dates = this.move([...this.dates], e.moved.oldIndex, e.moved.newIndex);
        this.$emit('dates', dates)

        let urls = [...this.urls];
        this.$emit('input', urls);

        let links = this.move([...this.links], e.moved.oldIndex, e.moved.newIndex);
        this.$emit('links', links);
      },
      update: function() {
        this.closeModal();
        this.$emit('input', this.urls)
      }
    },
    mounted: function () {

    }
  }

</script>

<style scoped>

  .image-list-box {
    cursor: pointer;

  }
  .image-list-box:hover {
    background:rgba(0,0,0,0.1);

  }

  .grid-card {
    border-radius: 5px;
    background:rgba(0,0,0,0.05);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  .selected-image {
    border-radius: 5px;
    background:rgba(0,0,0,0.05);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  .selected-image img, .grid-card img {
    object-fit:cover;
    cursor: pointer;
  }

  .selected-image img:hover, .grid-card img:hover {
    opacity: 0.9
  }
</style>
