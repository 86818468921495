<template>
  <div>
    <h1 class="title has-text-centered">{{displayname ? 'Hallo, ' + displayname : 'Hallo.'}}</h1>
    <div class="section">

      <!-- Allgemein Data & Form -->
      <p class="subtitle is-5"><strong>Allgemein</strong></p>
      <hr>

      <form class="form" style="margin-bottom:80px">

        <!-- DISPLAY NAME -->
        <div class="field is-horizontal">
          <label class="label field-label">Anzeigename</label>
          <div class="control field-body">
            <input type="text" class="input" v-model="displayname">
          </div>
        </div>

        <!-- USERNAME -->
        <div class="field is-horizontal">
          <label class="label field-label">Username</label>
          <div class="field-body field has-addons">
            <p class="control">
              <a class="button is-dark">@</a>
            </p>
            <p class="control">
              <input class="input" type="text" v-model="username">
            </p>
          </div>
        </div>

        <!-- EMAIL -->
        <div class="field is-horizontal">
          <label class="label field-label">E-Mail</label>
          <div class="control field-body">
            <input type="text" class="input" v-model="email" disabled>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label"></div>
          <div class="field-body">
            <p class="help is-danger">Aus Sicherheitsgründen kann die E-Mail derzeit nicht geändert werden !</p>
          </div>
        </div>

        <!-- SPEICHERN -->
        <div class="field is-horizontal">
          <div class="field-label"></div>
          <div class="field-body">
            <a
              class="button is-outlined is-dark"
              :class="{'is-loading' : isLoading }"
              :disabled="!isModified"
              @click="saveUserData">
              <strong>Speichern</strong>
            </a>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label"></div>
          <div class="field-body">
            <p class="help is-danger" v-if="isModified">Änderungen wurden noch nicht gespeichert</p>
          </div>
        </div>

      </form>

      <!-- Allgemein Data & Form -->
      <p class="subtitle is-5"><strong>Passwort ändern</strong></p>
      <hr>

      <form class="form" style="margin-bottom:80px">

        <div class="field is-horizontal">
          <div class="field-label">Neues Passwort</div>
          <div class="field-body control">
            <input type="password" class="input">
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label">Neues Passwort wiederholen</div>
          <div class="field-body control">
            <input type="password" class="input">
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label"></div>
          <div class="field-body">
            <a
              class="button is-outlined is-dark"
              :class="{'is-loading' : isLoading }"
              :disabled="!isModified"
              @click="saveUserData">
              <strong>Speichern</strong>
            </a>
          </div>
        </div>

      </form>

    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {

      }
    },
    computed: {
      shortid()            { return this.$store.state.user._shortid },
      id()                 { return this.$store.state.user._id },
      createdAt()          { return this.$store.state.user.createdAt },
      notifications()      { return this.$store.state.user.notifications },
      parentOrganization() { return this.$store.state.user.parentOrganization },
      email()              { return this.$store.state.user.email },
      isLoading()          { return this.$store.state.user.isLoading },
      isModified()         { return this.$store.state.user.isModified },
      displayname: {
        get() { return this.$store.state.user.displayname },
        set(nv) { this.$store.commit('user/UPDATE_DISPLAYNAME', nv) }
      },
      username: {
        get() { return this.$store.state.user.username },
        set(nv) { this.$store.commit('user/UPDATE_USERNAME', nv) }
      }
    },
    methods: {
      saveUserData() {
        if (this.isModified) { this.$store.dispatch('user/USER_SAVE'); }
      }
    },
    mounted() {
      if (this.isModified) {
        this.$store.dispatch('user/USER_FETCH');
      }
    }
  }
</script>

<style scoped>
  .is-centered {
    display:flex;
    flex-direction: column;
    align-items: center;
  }
</style>
