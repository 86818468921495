<template>
  <div>
    <div class="columns" v-if="!isLoading">
      <div class="column" style="padding-right:50px;">

        <!-- GENERAL SETTINGS -->
        <p class="subtitle is-5"><strong>Allgemein</strong></p>
        <p class="help">Allgemeine Informationen (Name, Beschreibung) werden nur intern verwendet</p>
        <hr>
        <form-asset-name v-model="meta.name" />
        <form-asset-description v-model="meta.description" />

        <!-- CHANNEL SPECIFIC -->
        <template v-if="assetType == 'channel'">
          <p class="subtitle is-5"><strong>Seiten</strong></p>
          <hr>
          <form-channel-editor
            v-model="children"
            :pages="availableChildren"
            :pagesMember="availableMemberChildren"
            :pageDurations="pageDurations"
            :timeDisplay="timeDisplay"
            @durations="onDurations"
            />
        </template>

        <!-- PAGE SPECIFIC -->
        <template v-if="assetType == 'page'">
          <p class="subtitle is-5"><strong>Layout</strong></p>
          <hr>
          <form-layout-picker v-model="style.layout" />
          <form-layout-editor
            v-model="children"
            :layout="style.layout"
            :modules="availableChildren"
            :modulesMember="availableMemberChildren"
            :positionStyles="positionStyles"/>
        </template>

        <!-- MOBILE PAGE SPECIFIC -->

        <template v-if="assetType == 'mobile-page'">
          <p class="subtitle is-5"><strong>Module</strong></p>
          <hr>
          <form-mobile-page-editor 
            v-model="children"
            :pages="availableChildren"
            :pagesMember="availableMemberChildren"
            :pageLinks="pageLinks"
            :sectionHeights="sectionHeights"
            @links="onLinks"
            @heights="onHeights"
          />
        </template>

        <!-- MOBILE PAGE SPECIFIC -->

        <template v-if="assetType == 'mobile-page'">
          <p class="subtitle is-5" style="margin-top:30px"><strong>App Icon</strong></p>
          <hr>
          <div class="field is-horizontal">
            <label class="label field-label help">512px x 512px (URL, *.png)</label>
            <input class="input field-body" type="text" v-model="pwa.icon512" />
          </div>
          <div class="field is-horizontal">
            <label class="label field-label help">192px x 192px (URL, *.png)</label>
            <input class="input field-body" type="text" v-model="pwa.icon192" />
          </div>
          <div class="field is-horizontal">
            <label class="label field-label help">180px x 180px (URL, *.png)</label>
            <input class="input field-body" type="text" v-model="pwa.icon180" />
          </div>
          <div class="field is-horizontal">
            <label class="label field-label help">120px x 120px (URL, *.png)</label>
            <input class="input field-body" type="text" v-model="pwa.icon120" />
          </div>
        </template>


        <!-- MODULE SPECIFIC -->
        <template v-if="assetType == 'module'">
          <p class="subtitle is-5" style="margin-top:30px;"><strong>Modul Inhalte</strong></p>
          <hr>
          <p class="help" style="margin-bottom:20px;">Kontrolliere Typ und Einstellungen des gewählten Moduls</p>
          <form-asset-module v-model="resource.module" />
          <div class="box" style="background:#E1E1E1; margin-top:20px;">
            <component v-bind:is="resource.module" v-model="resource.content" />
          </div>
          <p class="subtitle is-5" style="margin-top:40px;"><strong>Text Inhalte</strong></p>
          <hr>
          <form-asset-text-editor v-model="resource.text" />
        </template>

        <!-- STYLE SETTINGS -->
        <p class="subtitle is-5" style="margin-top:50px"><strong>Style</strong></p>
        <hr>
        <div class="field is-horizontal">
          <label class="label field-label help">Abstand Innen (px)</label>
          <input class="input field-body" type="number" max="100" min="0" v-model="style.innerSpace" />
        </div>
        <div class="field is-horizontal">
          <label class="label field-label help">Abstand Außen (px)</label>
          <input class="input field-body" type="number" max="100" min="0" v-model="style.outerSpace" />
        </div>
        <div class="field is-horizontal">
          <label class="label field-label help">Maximale Breite (%)</label>
          <input class="input field-body" type="number" max="100" min="0" v-model="style.maxWidth" />
        </div>
        <div class="field is-horizontal">
          <label class="label field-label help">Maximal Höhe (%)</label>
          <input class="input field-body" type="number" max="100" min="0" v-model="style.maxHeight" />
        </div>
        <div class="field is-horizontal">
          <label class="label field-label help">Horizontale Ausrichtung</label>
          <div class="select field-body">
            <select v-model="style.align">
              <option value="flex-start">Links</option>
              <option value="center">Mitte</option>
              <option value="flex-end">Rechts</option>
            </select>
          </div>
        </div>
        <div class="field is-horizontal">
          <label class="label field-label help">Vertikale Ausrichtung</label>
          <div class="select field-body">
            <select v-model="style.valign">
              <option value="flex-start">Oben</option>
              <option value="center">Mitte</option>
              <option value="flex-end">Unten</option>
            </select>
          </div>
        </div>

        <!-- BACKGROUND SETTINGS -->
        <p class="subtitle is-5"><strong>Hintergrund</strong></p>
        <hr>
        <form-asset-image
          v-model="style.backgroundImg"
          label="Hintergrund Bild" />
        <form-asset-background-fill
          :fill="style.backgroundFill"
          v-on:set-backgroundFill="style.backgroundFill = $event" />
        <form-asset-color
          label="Hintergrund Farbe"
          v-model="style.backgroundRgba"/>

      </div>
      <div class="column" style="max-width:300px; background:#222;">

        <div class="card has-background-dark" style="margin-bottom:10px; ">
          <header class="card-header">
            <div class="card-header-title has-text-light">
              <i class="fas fa-info-circle" style="margin-right:5px"></i>
              <span>Info</span>
            </div>
          </header>
          <div class="card-content">
            <div class="content">
              <p class="has-text-light">{{ assetToEdit._shortid }}</p>
              <p class="has-text-light">{{ assetToEdit._id }}</p>
            </div>
          </div>
        </div>

        <!-- META SETTINGS -->
        <div class="card has-background-dark" style="margin-bottom:10px; ">
          <header class="card-header">
            <div class="card-header-title has-text-light">
              <i class="fas fa-info-circle" style="margin-right:5px"></i>
              <span>Metadaten</span>
            </div>
          </header>
          <div class="card-content">
            <div class="content">
              <form-asset-region
                :region="meta.region"
                v-on:set-region="meta.region = $event" />
              <form-asset-label v-model="meta.labelColor" />
              <form-asset-tags :tags="meta.tags" />
              <form-asset-publish :modus="meta.modus" v-on:set-modus="setModus" />
            </div>
          </div>
        </div>
        <a
          class="button is-fullwidth is-link is-large"
          style="margin-bottom:10px;"
          :class="{'is-loading' : isSaving}"
          @click="requestSaveAsset"><strong>Speichern</strong></a>
        <a
          class="button is-fullwidth is-dark is-medium"
          @click="openPreview"
          v-if="assetToEdit.type == 'page'"
          ><strong>Vorschau</strong></a>
        <form-asset-members :member="member" :asset="assetToEdit"/>

      </div>

    </div>
    <loading-indicator v-else />
  </div>
</template>

<script>

  import FormAssetName            from '@/components/FormAssetName'
  import FormAssetDescription     from '@/components/FormAssetDescription'
  import FormAssetColor           from '@/components/FormAssetColor'
  import FormAssetLabel           from '@/components/FormAssetLabel'
  import FormAssetBackgroundFill  from '@/components/FormAssetBackgroundFill'
  import FormAssetImage           from '@/components/FormAssetImage'
  import FormAssetRegion          from '@/components/FormAssetRegion'
  import FormAssetTags            from '@/components/FormAssetTags'
  import FormAssetPublish         from '@/components/FormAssetPublish'
  import FormAssetMembers         from '@/components/FormAssetMembers'
  import FormLayoutPicker         from '@/components/FormLayoutPicker'
  import FormLayoutEditor         from '@/components/FormLayoutEditor'
  import FormChannelEditor        from '@/components/FormChannelEditor'
  import FormMobilePageEditor     from '@/components/FormMobilePageEditor'
  import FormAssetTextEditor      from '@/components/FormAssetTextEditor'
  import FormAssetModule          from '@/components/FormAssetModule'

  import ModuleYoutube            from '@/components/modules/ModuleYoutubeForm'
  import ModuleVimeo              from '@/components/modules/ModuleVimeoForm'
  import ModuleText               from '@/components/modules/ModuleTextForm'
  import ModuleImage              from '@/components/modules/ModuleImageForm'
  import ModuleVideo              from '@/components/modules/ModuleVideoForm'
  import ModuleImageGallery       from '@/components/modules/ModuleImageGalleryForm'
  import ModuleTourdataMulti      from '@/components/modules/ModuleTourdataMultiForm'
  import ModuleDateTime           from '@/components/modules/ModuleDateTimeForm'
  import ModuleWeather            from '@/components/modules/ModuleWeatherForm'
  import ModuleIframe             from '@/components/modules/ModuleIframeForm'
  import ModuleNewsTicker         from '@/components/modules/ModuleNewsTickerForm'
  import ModulePanomaxWebcam      from '@/components/modules/ModulePanomaxWebcamForm'

  import LoadingIndicator from '@/components/LoadingIndicator'

  export default {
    props: ['assetType','assetTypeToLoad'],
    computed: {
      availableChildren()       { return this.$store.getters["assets/" + this.assetTypeToLoad + "s"] },
      availableMemberChildren() { return this.$store.getters["assets/" + this.assetTypeToLoad + "sMember"] }
    },
    data () {
      return {
        // General Fields
        assetToEdit: null,
        isLoading: true,
        isSaving: false,

        // Asset Specific Fields
        meta: {
          name: '',
          description: '',
          labelColor: '#FFFFFF',
          region: 'AT',
          tags: [],
          modus: 'active',
          public: false
        },
        timeDisplay: {
          active : false,
          position : 'bottom',
          foregroundColor : "#FFFFFF",
          backgroundColor : "#000000"
        },
        pwa : {
          icon72  : "",
          icon96  : "",
          icon120 : "",
          icon128 : "",
          icon144 : "",
          icon152 : "",
          icon180 : "",
          icon192 : "",
          icon384 : "",
          icon512 : ""
        },
        style: {
          backgroundImg  : '',
          backgroundFill : 'cover',
          backgroundPos  : '',
          backgroundHex  : '#FFFFFF',
          backgroundRgba : { r: 255, g: 255, b: 255, a: 0 },
          layout : 'layout-xy-test-1',
          innerSpace : 0,
          outerSpace : 0,
          maxWidth   : 100,
          maxHeight  : 100,
          align      : 'center',
          valign     : 'center'
        },
        timeslots: [],
        positionStyles: [],
        pageDurations: [],
        pageLinks: [],
        sectionHeights: [],
        resource: {
          text: '',
          textType: 'html',
          module: '',
          content: {}
        },
        children: [],
        member: []
      }
    },
    components: {
      'form-asset-name'             : FormAssetName,
      'form-asset-description'      : FormAssetDescription,
      'form-asset-color'            : FormAssetColor,
      'form-asset-label'            : FormAssetLabel,
      'form-asset-background-fill'  : FormAssetBackgroundFill,
      'form-asset-image'            : FormAssetImage,
      'form-asset-region'           : FormAssetRegion,
      'form-asset-publish'          : FormAssetPublish,
      'form-asset-members'          : FormAssetMembers,
      'form-asset-tags'             : FormAssetTags,
      'form-layout-picker'          : FormLayoutPicker,
      'form-layout-editor'          : FormLayoutEditor,
      'form-channel-editor'         : FormChannelEditor,
      'form-mobile-page-editor'     : FormMobilePageEditor,
      'form-asset-text-editor'      : FormAssetTextEditor,
      'form-asset-module'           : FormAssetModule,
      'module-youtube'              : ModuleYoutube,
      'module-vimeo'                : ModuleVimeo,
      'module-text'                 : ModuleText,
      'module-image'                : ModuleImage,
      'module-video'                : ModuleVideo,
      'module-image-gallery'        : ModuleImageGallery,
      'module-tourdata-multi'       : ModuleTourdataMulti,
      'module-date-time'            : ModuleDateTime,
      'module-iframe'               : ModuleIframe,
      'module-weather'              : ModuleWeather,
      'loading-indicator'           : LoadingIndicator,
      'module-news-ticker'          : ModuleNewsTicker,
      'module-panomax-webcam'       : ModulePanomaxWebcam
    },
    methods: {
      onDurations(durations) {
        this.pageDurations = durations;

      },
      onLinks(links) {
        this.pageLinks = links;
      },
      onHeights(heights) {
        this.sectionHeights = heights;
      },
      openPreview() {
        window.open("https://ois-viewer.herokuapp.com/preview/" + this.assetToEdit._id, "_blank");
      },
      requestSaveAsset() {
        this.isSaving = true;

        this.pageDurations = this.children.map((page,index) => {
          let d = this.pageDurations[index];
          if (!d)       { d = 15;  }
          if (isNaN(d)) { d = 15;  }
          if (d > 100000)  { d = 100000; }
          if (d < 5)    { d = 5;  }
          return d;
        });

        const body = {
          type          : this.assetType,
          meta          : this.meta,
          style         : this.style,
          pwa           : this.pwa,
          timeDisplay   : this.timeDisplay,
          timeslots     : this.timeslots,
          positionStyles: this.positionStyles,
          pageDurations : this.pageDurations,
          pageLinks     : this.pageLinks,
          sectionHeights: this.sectionHeights,
          resource      : this.resource,
          children      : this.children
        };


        console.log(body)

        const id = this.assetToEdit._id;
        this.$store.dispatch('assets/UPDATE_ASSET', {id, body})
        .then((payload) => {

        })
        .catch((err) => {

        })
        .finally(() => {
          this.isSaving = false;
        });
      },
      setModus(event) {
        this.meta.modus = event;
        this.meta.public = event == "active" ? false : true;
      }
    },
    mounted() {

      const id = this.$route.params.id;
      this.$store.dispatch('assets/GET_ASSET', id)
      .then((payload) => {
        if(payload[this.assetType]) {
          const asset         = payload[this.assetType];
          this.assetToEdit    = asset;
          this.meta           = asset.meta;
          this.style          = asset.style;
          this.pwa            = asset.pwa;
          this.timeDisplay    = asset.timeDisplay;
          this.timeslots      = asset.timeslots;
          this.positionStyles = asset.positionStyles;
          this.pageDurations  = asset.pageDurations;
          this.pageLinks      = asset.pageLinks;
          this.sectionHeights = asset.sectionHeights;
          this.resource       = asset.resource;
          this.children       = asset.children;
          this.member         = asset.member;
        }
      })
      .catch((err) => {
        // maybe return ?
      })
      .finally(() => {
        this.isLoading = false;
      });

    }
  }

</script>

<style scoped>

  .table {
    width: 100%
  }

  .card-content {
    padding: 15px;
  }

  hr {
    color:black;
    border: 1px solid rgba(0,0,0,0.1);
  }

</style>
