<template>
  <div class="columns">

    <div class="column">

      <!-- IAMGE BORDER SIZE -->
      <div class="field">
        <label class="label">Iframe Code eingeben</label>
        <div class="control">
          <textarea class="textarea" v-model="iframeString" v-on:change="update">
          </textarea>
        </div>
      </div>
      <!--            -->

    </div>

    <!-- /////////////////////////////////////////////////////////////////// -->

    <div class="column">
      <div class="" v-html="iframeString">

      </div>
    </div>

  </div>
</template>

<script>

  export default {
    props: ['value'],
    data() {
      return {
        iframeString: this.value.iframeString ? this.value.iframeString : ``
      }
    },
    components : {

    },
    methods: {
      update() {
        this.$emit('input', {
          iframeString          : this.iframeString,
        });
      }
    }
  }

</script>

<style scoped>
</style>
