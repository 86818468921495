<template>
  <div class="columns">

    <div class="column">

      <!-- AUSWAHL KAMERA -->
      <div class="field">
        <label class="label">Panomax Webcam auswählen</label>
        <div class="select is-link">

          <select v-model="selectedCam" v-on:change="update">
            <option v-for="(cam, index) in cams" :value="cam" :key="cam.camId">{{ cam.camId }} {{ cam.name }} {{ cam.customerName }} </option>
          </select>

        </div>


      </div>
      <div class="mt-5 box">
        <p><strong>Name : </strong> {{ selectedCam.name }} </p>
        <p><strong>Zusatz : </strong> {{ selectedCam.customerName }} </p>
        <p><strong>Kamera ID : </strong> {{ selectedCam.camId }} </p>
      </div>

      <div style="width:640px; height:360px; overflow:hidden">
        <img
          class="preview"
          :src="selectedCam.url"
          :style="{

            animationDuration: `${animationDuration}s`,
            animationDirection: `${animationDirection}`,
            height:`100%`,
            maxWidth:`100%`,
            objectFit:`cover`,
            objectPosition:`0%`,
          }"/>
      </div>
      <!--            -->

      <!-- ANIMATION DURATION -->
      <div class="field">
        <label class="label">Animations Dauer (s)</label>
        <div class="control">
          <input class="input" type="number" v-model.number="animationDuration" v-on:change="update" />
        </div>
      </div>
      <!--            -->

      <!-- ANIMATION DIRECTION -->
      <div class="field">
        <label class="label">Animations Richtung </label>
        <div class="select is-link">
          <select v-model="animationDirection" v-on:change="update">
            <option value="normal">Vorwärts</option>
            <option value="reverse">Rückwerts</option>
            <option value="alternate">Vorwärts Hin und Her</option>
            <option value="alternate-reverse">Rückwerts Hin und Her</option>
          </select>
        </div>
      </div>
      <!--            -->

      <!-- IMAGE SPACE -->

      <!--            -->

    </div>

    <!-- /////////////////////////////////////////////////////////////////// -->
<!-- 
    <div class="column">
      <form-asset-image
        v-model="imageUrl"
        v-on:input="update"
        label="Bild aus Mediathek wählen" />
    </div> -->

  </div>
</template>

<script>

  import FormAssetImage from '@/components/FormAssetImage'
  import availablePanomaxWebcams from '@/_helpers/panomax.helper.js'

  export default {
    props: ['value'],
    data() {
      return {
        selectedCam        : this.value.selectedCam ? this.value.selectedCam : {},
        animationDuration  : this.value.animationDuration ? this.value.animationDuration : 100,
        animationDirection : this.value.animationDirection ? this.value.animationDirection : 'normal',

        ///////
        cams : availablePanomaxWebcams
        
      }
    },
    components : {
      FormAssetImage
    },
    methods: {
      update() {
        this.$emit('input', {
          selectedCam        : this.selectedCam,
          animationDuration  : this.animationDuration,
          animationDirection : this.animationDirection 
        });

        /*
          {
            selectedCam : {
              camId,
              customerName,
              elevation,
              name, 
              url
            },
            animationDuration,
            animationDirection
          }
        */
      }
    }
  }

</script>

<style scoped>

  .preview {
    animation: linear infinite;
    animation-name: scrollImage;
  }

  /* @keyframes scrollImage {
    from {
      left: 0;
    }
    to {
      left: -100%;
    }
  } */

  @keyframes scrollImage {
    0% {
      object-position: 0;
    }
    100% { 
      object-position: 100%; 
    }
    /* 100% {
      object-position: 0;     
    } */
  }
</style>
